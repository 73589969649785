import { css } from '@emotion/react'
import { container } from '~/base'

export function containerStyle({ width }) {
  return css`
    ${container({ width, padding: true })}
    margin-left: auto;
    margin-right: auto;
  `
}
