import NextLink from 'next/link'

type Props = React.ComponentProps<typeof NextLink> & {
  href: any
  css?: any
  children: React.ReactNode
}

export function Link({ href, children, css, ...props }: Props) {
  return (
    <NextLink href={href} passHref css={css} {...props}>
      {children}
    </NextLink>
  )
}
