import { css } from '@emotion/react'
import { breakpoints, radius } from '~/base'

export const wrapper = css``

export const content = css`
  display: flex;
  flex-wrap: wrap;
  margin: -24px;
  align-items: center;
  margin-bottom: 16px;

  @media ${breakpoints.xxlarge} {
    margin-bottom: 0;
  }
`

export const contentReverse = css`
  flex-direction: row-reverse;
`

const sharedItems = css`
  flex: 1;
  padding: 24px;
  min-width: 280px;

  @media ${breakpoints.large} {
    flex-basis: 50%;
  }
`

export const media = css`
  ${sharedItems}
`

export const main = css`
  ${sharedItems}
`

/** MOD: main left */
export const mainLeft = css`
  @media ${breakpoints.large} {
    padding-right: 72px;
  }

  @media ${breakpoints.xxlarge} {
    padding-right: 120px;
  }
`

/** MOD: main right */
export const mainRight = css`
  @media ${breakpoints.large} {
    padding-left: 72px;
  }

  @media ${breakpoints.xxlarge} {
    padding-left: 120px;
  }
`

export const image = css`
  display: block;
  max-width: 100%;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
`

export const cta = css`
  color: var(--colors-primary);
  margin-top: 40px;
`

export const ctaIcon = css`
  height: 36px;
`

/** Contact */

export const contact = css`
  display: flex;
  justify-content: center;

  @media ${breakpoints.xxlarge} {
    width: 50%;
    margin-top: -72px;
  }
`

/** MOD: `contact` left */
export const contactLeft = css`
  @media ${breakpoints.xxlarge} {
    margin-left: auto;
    justify-content: flex-start;
  }
`

/** MOD: `contact` right */
export const contactRight = css``

export const contactBox = css`
  display: flex;
  flex-direction: column;
  ${radius('medium')}
  background: var(--colors-primary);
  color: #fff;
  padding: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media ${breakpoints.medium} {
    flex-direction: row;
    align-items: center;
  }
`

/** MOD: `contactBox` left */
export const contactBoxLeft = css`
  @media ${breakpoints.xxlarge} {
    margin-left: -48px;
  }
`

/** MOD: `contactBox` right */
export const contactBoxRight = css`
  @media ${breakpoints.xxlarge} {
    margin-right: -216px;
  }
`

export const contactBrand = css`
  ${radius('medium')}
  background: #fff;
  padding: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.large} {
    border-bottom-left-radius: 36px;
  }
`

export const contactLogo = css`
  width: 220px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const contactLogoImage = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const contactMain = css`
  padding: 24px;
  margin: 0;
  list-style-type: none;

  @media ${breakpoints.medium} {
    padding: 24px 40px;
  }

  a {
    text-decoration: none;
  }
`
