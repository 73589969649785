import { css } from '@emotion/react'
import { breakpoints } from '~/base'

export const desktop = css`
  display: none;

  @media ${breakpoints.large} {
    display: block;
  }
`

export const mobile = css`
  display: block;

  @media ${breakpoints.large} {
    display: none;
  }
`
