import { css } from '@emotion/react'

export const wrapper = css``

export const canvas = css`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  overflow: hidden;

  .player {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const heading = css`
  text-align: center;
  margin-bottom: 2rem;
`
