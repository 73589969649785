import { css } from '@emotion/react'
import { breakpoints } from './breakpoints'

type Options = {
  size?: FontSize
  weight?: FontWeight
}

export type FontSize = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'

export type FontWeight = 'regular' | 'bold'

const sizeVariants: any = {
  xxsmall: css`
    font-size: 14px;

    @media ${breakpoints.medium} {
      font-size: 14px;
    }
  `,
  xsmall: css`
    font-size: 16px;

    @media ${breakpoints.medium} {
      font-size: 16px;
    }
  `,
  small: css`
    font-size: 16px;

    @media ${breakpoints.medium} {
      font-size: 18px;
    }
  `,
  medium: css`
    font-size: 18px;

    @media ${breakpoints.medium} {
      font-size: 22px;
    }
  `,
  large: css`
    font-size: 30px;

    @media ${breakpoints.medium} {
      font-size: 40px;
    }
  `,
  xlarge: css`
    font-size: 40px;

    @media ${breakpoints.medium} {
      font-size: 60px;
    }
  `,
  xxlarge: css`
    font-size: 40px;

    @media ${breakpoints.medium} {
      font-size: 68px;
    }

    @media ${breakpoints.large} {
      font-size: 90px;
    }
  `,
}

const weightVariants = {
  regular: css`
    font-weight: normal;
  `,
  bold: css`
    font-weight: bold;
  `,
}

export function font({ weight, size }: Options) {
  const styles: any = []
  // if (style === 'bold') {
  //   return css`
  //     font-family: tenon, sans-serif;
  //     font-weight: 700;
  //     font-style: normal;
  //   `
  // }

  if (size) {
    styles.push(sizeVariants[size])
  }

  if (weight) {
    styles.push(weightVariants[weight])
  }

  return styles
}
