export function HandThumbRightIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.314 79.418" {...props}>
      <g data-name="Gruppe 170" fill="none" stroke="currentColor" strokeWidth={3}>
        <path
          data-name="Pfad 53"
          d="M27.131 77.831s19.034 1.04 27.324-7.719c1.813-1.92 9.7-.948 9.7-.948l.19-30.963s-5.906.434-8.452-1.247c-2.85-1.88-21.308-19.405-22.717-22.364s-2.537-10.107-2.642-11.9-8.346-2.747-9.086 6.444c-.369 4.582 1.556 9.045 3.626 13.02 1.172 2.251 5.567 7.183 2.962 9.693-1.935 1.865-4.354 1.051-8.912 1.051H5.998a5.6 5.6 0 00-4.5 5.734 6.394 6.394 0 004.739 6.147h3.617v.007s-6.085-.455-6.085 5.785c0 4.624 2.206 6.575 6.83 6.575H9.482s-4.428-.064-4.428 5.574a5.632 5.632 0 004.423 5.576 5.01 5.01 0 00-1.82 4.609c.354 3.09 3.919 4.751 6.174 4.929s13.3-.003 13.3-.003z"
          strokeLinejoin="round"
        />
        <path
          data-name="Pfad 54"
          d="M21.242 57.143l3.59-.011s5.56-.43 5.56-6.218-5.667-6.13-5.667-6.13H9.853"
          strokeMiterlimit={10}
        />
        <path
          data-name="Pfad 55"
          d="M19.964 44.781l3.408-.011a5.911 5.911 0 005.278-6.028c0-5.5-5.636-5.708-5.636-5.708"
          strokeMiterlimit={10}
        />
        <path
          data-name="Pfad 56"
          d="M9.476 68.292l17.914-.1s3.837-.764 3.837-5.474c0-6.09-5.4-5.574-5.4-5.574H10.599"
          strokeMiterlimit={10}
        />
        <path
          data-name="Pfad 57"
          d="M75.815 69.726a1.7 1.7 0 01-1.7 1.7h-8.072a1.7 1.7 0 01-1.7-1.7V37.219a1.7 1.7 0 011.7-1.7h8.078a1.7 1.7 0 011.695 1.7z"
          strokeLinejoin="round"
        />
        <path
          data-name="Pfad 58"
          d="M27.131 77.831s3.506-1.036 3.506-4.362c0-5.176-4.392-5.271-4.392-5.271"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}
