import '@csstools/normalize.css'
import { Global } from '@emotion/react'
import { apiPlugin, storyblokInit } from '@storyblok/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { global } from '~/base'
import { LotteryCTABlock } from '~/blocks/LotteryCTABlock'
import { LotteryFormBlock } from '~/blocks/LotteryFormBlock'
import { ContactBlock } from '~/features/exhibitors/ContactBlock'
import { ContentBlock } from '~/features/exhibitors/ContentBlock'
import { ExhibitorTimelineBlock } from '~/features/exhibitors/ExhibitorTimelineBlock'
import { ExhibitorsBlock } from '~/features/exhibitors/ExhibitorsBlock'
import { FeaturesBlock } from '~/features/exhibitors/FeaturesBlock'
import { MemberBlock } from '~/features/exhibitors/MemberBlock'
import { VideoBlock } from '~/features/exhibitors/VideoBlock'
import { PageMatomoBlock } from '~/features/pages/blocks/PageMatomoBlock'
import { PageTextBlock } from '~/features/pages/blocks/PageTextBlock'
import { PageTitleBlock } from '~/features/pages/blocks/PageTitleBlock'

declare global {
  interface Window {
    _paq: any
  }
}

storyblokInit({
  accessToken: process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN as string,
  use: [apiPlugin],
  components: {
    PageTitleBlock,
    PageTextBlock,
    PageMatomoBlock,
    ExhibitorContentBlock: ContentBlock,
    ExhibitorContactBlock: ContactBlock,
    ExhibitorFeaturesBlock: FeaturesBlock,
    ExhibitorMemberBlock: MemberBlock,
    ExhibitorVideoBlock: VideoBlock,
    ExhibitorsBlock: ExhibitorsBlock,
    ExhibitorTimelineBlock,
    LotteryCTABlock,
    LotteryFormBlock,
  },
})

const App = ({ Component, pageProps }) => {
  const router = useRouter()

  useEffect(() => {
    function handleRouteChange(url) {
      if (window && window._paq) {
        window._paq.push(['setCustomUrl', url])
        window._paq.push(['setDocumentTitle', document.title])
        window._paq.push(['trackPageView'])
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d2271c" />
        <meta name="msapplication-TileColor" content="#d2271c" />
        <meta name="theme-color" content="#ffffff" />
        <title>
          10. Berufs- & Bildungstage 2024 | 27./28. September 2024 | Next-Step – Bildung. Beruf. Liechtenstein
        </title>
        <meta
          name="description"
          content="Bereits zum 10. Mal wird am 27./28. September 2024 im SAL in Schaan die next-step Berufs- & Bildungstage durchgeführt."
        />
        <meta name="facebook-domain-verification" content="dfinkk7vssfor8kygh9n9tu6vx4g20" />
      </Head>
      <Global styles={global} />
      <Component {...pageProps} key={router.asPath} />

      <script
        dangerouslySetInnerHTML={{
          __html: `var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(["setCookieDomain", "*.next-step.li"]);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://analytics.sitewalk.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '79']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
          })();`,
        }}
      />
      <noscript>
        <p>
          <img src="https://analytics.sitewalk.com/matomo.php?idsite=79&amp;rec=1" style={{ border: '0' }} alt="" />
        </p>
      </noscript>
    </>
  )
}

export default App
