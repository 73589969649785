import { css } from '@emotion/react'

import { Width, widths } from './width'

type Options = {
  width?: Width
  color?: 'primary' | 'meta'
  align?: 'left' | 'right'
  /** whether or not to add padding for inner content. */
  content?: boolean
}

type ContentOptions = {
  width?: Width
}

const colorVariants = {
  primary: css`
    background-color: var(--colors-primary);
    color: #fff;
  `,
  meta: css`
    background-color: var(--colors-meta);
    color: var(--colors-base);
  `,
}

const alignVariants = {
  left: css`
    margin-right: auto;

    > * {
      margin-left: auto;
    }
  `,
  right: css`
    margin-left: auto;

    > * {
      margin-right: auto;
    }
  `,
}

export function rack({ width = 'medium', color, content = false, align = 'left' }: Options) {
  return css`
    width: calc(100vw - ((100vw - ${widths[width]}px) / 2));
    max-width: 100%;

    ${color && colorVariants[color]}

    ${align && alignVariants[align]}

    ${content &&
    css`
      padding-left: var(--gap);
      padding-right: var(--gap);
    `}
  `
}

export function rackContent({ width = 'medium' }: ContentOptions = {}) {
  return css`
    width: calc(${widths[width]}px - var(--gap));
    max-width: 100%;
  `
}
