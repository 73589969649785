import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

import { ExhibitorStory, useExhibitors } from '..'
import { locations } from './locations'
import { useExhibitorLocations } from './useExhibitorLocations'

import * as styles from './PlanDesktop.css'

export function PlanDesktop() {
  const exhibitors = useExhibitors()
  const exhibitorLocations = useExhibitorLocations(exhibitors)

  const [activeExhibitor, setActiveExhibitor] = useState<ExhibitorStory>()

  function onMouseEnter(e) {
    const booth = e.target.getAttribute('data-booth')

    const exhibitor = exhibitorLocations[booth]
    if (exhibitor) {
      setActiveExhibitor(exhibitor)
    } else {
      setActiveExhibitor(undefined)
    }
  }

  function onMouseLeave(e) {
    setActiveExhibitor(undefined)
  }

  function onClick(e) {
    if (activeExhibitor) {
      window.location.href = `/aussteller/${activeExhibitor.slug}`
    }
  }

  const activeLocation = activeExhibitor ? locations[activeExhibitor!.content.location_site!] : null

  return (
    <div css={styles.wrapper}>
      <AnimatePresence>
        {activeExhibitor && (
          <motion.div
            css={styles.active}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: '0' }}
            exit={{ opacity: 0, y: '100%' }}
            style={
              {
                '--color-path-base': activeLocation.colorBase,
                '--color-path-text': activeLocation.colorText,
              } as any
            }
          >
            <div css={styles.activeBox}>
              <div css={styles.activeMain}>
                <div css={styles.activeBooth}>{activeExhibitor!.content.location_booth}</div>
                <div css={styles.activeName}>{activeExhibitor.name}</div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <svg id="Plan" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 799.1 525.8">
        <g id="Stände">
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.06' in exhibitorLocations}
            data-booth="2.06"
            id="BOOTH_x5F_2.06"
            x="259.61"
            y="140.06"
            width="31.7"
            height="91.89"
            transform="translate(461.46 -89.47) rotate(90)"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.08' in exhibitorLocations}
            data-booth="2.08"
            id="BOOTH_x5F_2.08"
            x="284.67"
            y="339.38"
            width="25.51"
            height="41.1"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.03' in exhibitorLocations}
            data-booth="2.03"
            id="BOOTH_x5F_2.03"
            x="216.77"
            y="286.8"
            width="25.51"
            height="41.1"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.12' in exhibitorLocations}
            data-booth="2.12"
            id="BOOTH_x5F_2.12"
            x="284.67"
            y="234.45"
            width="31.38"
            height="25.92"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.13' in exhibitorLocations}
            data-booth="2.13"
            id="BOOTH_x5F_2.13"
            x="316.05"
            y="234.45"
            width="31.38"
            height="25.92"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.11' in exhibitorLocations}
            data-booth="2.11"
            id="BOOTH_x5F_2.11"
            x="284.7"
            y="260.59"
            width="25.51"
            height="26.21"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.05' in exhibitorLocations}
            data-booth="2.05"
            id="BOOTH_x5F_2.05"
            x="216.73"
            y="234.45"
            width="25.55"
            height="25.92"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.04' in exhibitorLocations}
            data-booth="2.04"
            id="BOOTH_x5F_2.04"
            x="216.77"
            y="260.59"
            width="25.51"
            height="26.21"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.02' in exhibitorLocations}
            data-booth="2.02"
            id="BOOTH_x5F_2.02"
            x="216.73"
            y="327.9"
            width="25.55"
            height="25.92"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.01' in exhibitorLocations}
            data-booth="2.01"
            id="BOOTH_x5F_2.01"
            x="216.77"
            y="354.05"
            width="25.51"
            height="26.21"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.10' in exhibitorLocations}
            data-booth="2.10"
            id="BOOTH_x5F_2.10"
            x="284.7"
            y="286.97"
            width="25.51"
            height="26.21"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.09' in exhibitorLocations}
            data-booth="2.09"
            id="BOOTH_x5F_2.09"
            x="284.7"
            y="313.18"
            width="25.51"
            height="26.21"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.07' in exhibitorLocations}
            data-booth="2.07"
            id="BOOTH_x5F_2.07"
            x="284.7"
            y="380.64"
            width="25.51"
            height="26.21"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.15' in exhibitorLocations}
            data-booth="2.15"
            id="BOOTH_x5F_2.15"
            x="404.16"
            y="168.83"
            width="69.26"
            height="55.45"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'2.14' in exhibitorLocations}
            data-booth="2.14"
            id="BOOTH_x5F_2.14"
            x="373.16"
            y="186.24"
            width={31}
            height="38.03"
            style={{
              fill: 'rgba(134,179,97,.4)',
              stroke: '#86b361',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.08' in exhibitorLocations}
            data-booth="1.08"
            id="BOOTH_x5F_1.08"
            x="409.67"
            y={490}
            width="36.14"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.10' in exhibitorLocations}
            data-booth="1.10"
            id="BOOTH_x5F_1.10"
            x="445.81"
            y={490}
            width="67.01"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.11' in exhibitorLocations}
            data-booth="1.11"
            id="BOOTH_x5F_1.11"
            x="512.82"
            y={490}
            width="22.84"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.12' in exhibitorLocations}
            data-booth="1.12"
            id="BOOTH_x5F_1.12"
            x="535.46"
            y={490}
            width="22.84"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.13' in exhibitorLocations}
            data-booth="1.13"
            id="BOOTH_x5F_1.13"
            x="558.3"
            y={490}
            width="22.84"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.14' in exhibitorLocations}
            data-booth="1.14"
            id="BOOTH_x5F_1.14"
            x="581.13"
            y={490}
            width="22.84"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.16' in exhibitorLocations}
            data-booth="1.16"
            id="BOOTH_x5F_1.16"
            x="642.4"
            y="454.43"
            width="28.35"
            height="36.14"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.17' in exhibitorLocations}
            data-booth="1.17"
            id="BOOTH_x5F_1.17"
            x="640.44"
            y="391.02"
            width="19.15"
            height="36.14"
            transform="translate(240.93 1059.11) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.26' in exhibitorLocations}
            data-booth="1.26"
            id="BOOTH_x5F_1.26"
            x="637.2"
            y="368.56"
            width="25.62"
            height="36.14"
            transform="translate(263.38 1036.65) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.21' in exhibitorLocations}
            data-booth="1.21"
            id="BOOTH_x5F_1.21"
            x="683.37"
            y="385.91"
            width="22.12"
            height="43.4"
            transform="translate(286.83 1102.04) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.25' in exhibitorLocations}
            data-booth="1.25"
            id="BOOTH_x5F_1.25"
            x="683.07"
            y="363.48"
            width="22.72"
            height="43.4"
            transform="translate(309.25 1079.62) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.27' in exhibitorLocations}
            data-booth="1.27"
            id="BOOTH_x5F_1.27"
            x="623.38"
            y="291.23"
            width="28.35"
            height="36.14"
            transform="translate(328.25 946.85) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.24' in exhibitorLocations}
            data-booth="1.24"
            id="BOOTH_x5F_1.24"
            x="682.57"
            y="291.23"
            width="28.35"
            height="36.14"
            transform="translate(387.45 1006.04) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.23' in exhibitorLocations}
            data-booth="1.23"
            id="BOOTH_x5F_1.23"
            x="718.65"
            y="291.23"
            width="28.35"
            height="36.14"
            transform="translate(423.52 1042.12) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.19' in exhibitorLocations}
            data-booth="1.19"
            id="BOOTH_x5F_1.19"
            x="718.98"
            y="461.52"
            width="28.35"
            height="36.14"
            transform="translate(1212.74 -253.56) rotate(90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.18' in exhibitorLocations}
            data-booth="1.18"
            id="BOOTH_x5F_1.18"
            x="682.9"
            y="461.52"
            width="28.35"
            height="36.14"
            transform="translate(1176.67 -217.49) rotate(90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.20' in exhibitorLocations}
            data-booth="1.20"
            id="BOOTH_x5F_1.20"
            x="763.49"
            y="358.71"
            width="28.35"
            height="75.67"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.22' in exhibitorLocations}
            data-booth="1.22"
            id="BOOTH_x5F_1.22"
            x="763.49"
            y="322.39"
            width="28.35"
            height="36.14"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.32' in exhibitorLocations}
            data-booth="1.32"
            id="BOOTH_x5F_1.32"
            x="561.72"
            y="265.4"
            width="36.14"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.31' in exhibitorLocations}
            data-booth="1.31"
            id="BOOTH_x5F_1.31"
            x="498.2"
            y="269.15"
            width="36.14"
            height="28.35"
            transform="translate(799.59 -232.94) rotate(90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.30' in exhibitorLocations}
            data-booth="1.30"
            id="BOOTH_x5F_1.30"
            x="474.57"
            y="342.84"
            width="28.35"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
              strokeWidth: '1.1px',
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.29' in exhibitorLocations}
            data-booth="1.29"
            id="BOOTH_x5F_1.29"
            x="507.06"
            y="367.71"
            width="28.35"
            height="35.88"
            transform="translate(135.58 906.88) rotate(-90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.28' in exhibitorLocations}
            data-booth="1.28"
            id="BOOTH_x5F_1.28"
            x="539.17"
            y="371.49"
            width="60.19"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
              strokeWidth: '.97px',
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.05' in exhibitorLocations}
            data-booth="1.05"
            id="BOOTH_x5F_1.05"
            x="395.4"
            y="413.76"
            width="60.24"
            height="32.4"
            transform="translate(855.47 4.44) rotate(90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.09' in exhibitorLocations}
            data-booth="1.09"
            id="BOOTH_x5F_1.09"
            x="441.1"
            y="426.89"
            width="33.84"
            height="32.54"
            transform="translate(901.17 -14.86) rotate(90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
              strokeWidth: '1.1px',
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.15' in exhibitorLocations}
            data-booth="1.15"
            id="BOOTH_x5F_1.15"
            x="506.85"
            y="367.56"
            width="60.24"
            height="124.78"
            transform="translate(966.92 -107.01) rotate(90)"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.04' in exhibitorLocations}
            data-booth="1.04"
            id="BOOTH_x5F_1.04"
            x="409.31"
            y="327.55"
            width="65.26"
            height="72.28"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.02' in exhibitorLocations}
            data-booth="1.02"
            id="BOOTH_x5F_1.02"
            x="382.47"
            y="262.75"
            width="52.39"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.01' in exhibitorLocations}
            data-booth="1.01"
            id="BOOTH_x5F_1.01"
            x="434.87"
            y="262.75"
            width="36.14"
            height="28.35"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.03' in exhibitorLocations}
            data-booth="1.03"
            id="BOOTH_x5F_1.03"
            x="346.28"
            y="297.69"
            width="28.35"
            height="72.28"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.07' in exhibitorLocations}
            data-booth="1.07"
            id="BOOTH_x5F_1.07"
            x="346.28"
            y="455.65"
            width="28.35"
            height="36.91"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'1.06' in exhibitorLocations}
            data-booth="1.06"
            id="BOOTH_x5F_1.06"
            x="346.28"
            y="418.85"
            width="28.35"
            height="36.91"
            style={{
              fill: 'rgba(249,177,25,.4)',
              stroke: '#f9b119',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'3.01' in exhibitorLocations}
            data-booth="3.01"
            id="BOOTH_x5F_3.01"
            x="36.77"
            y="216.12"
            width="70.68"
            height="70.68"
            style={{
              fill: 'rgba(227,6,19,.2)',
              stroke: '#e30613',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'3.02' in exhibitorLocations}
            data-booth="3.02"
            id="BOOTH_x5F_3.02"
            x="107.27"
            y="216.12"
            width="70.68"
            height="70.68"
            style={{
              fill: 'rgba(227,6,19,.2)',
              stroke: '#e30613',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'3.03' in exhibitorLocations}
            data-booth="3.03"
            id="BOOTH_x5F_3.03"
            x="36.92"
            y="388.08"
            width="70.68"
            height="70.68"
            transform="translate(495.68 351.16) rotate(90)"
            style={{
              fill: 'rgba(227,6,19,.2)',
              stroke: '#e30613',
              strokeMiterlimit: 10,
            }}
          />
          <rect
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-active={'3.04' in exhibitorLocations}
            data-booth="3.04"
            id="BOOTH_x5F_3.03"
            x="107.42"
            y="388.08"
            width="70.68"
            height="70.68"
            transform="translate(566.18 280.66) rotate(90)"
            style={{
              fill: 'rgba(227,6,19,.2)',
              stroke: '#e30613',
              strokeMiterlimit: 10,
            }}
          />
        </g>
        <g id="Nr">
          <path d="M447.08,276.39l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M449.56,279.85c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M451.65,277.67c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM454.86,277.67c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M458.55,276.39l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path d="M402.64,276.39l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M405.12,279.85c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M407.21,277.67c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM410.42,277.67c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M412.31,279.7c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M355.36,472.29l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M357.84,475.75c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M359.93,473.57c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM363.14,473.57c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M367.84,471.83h-2.9v-1.01h4.21v.71l-2.33,4.79h-1.24l2.25-4.5Z" style={{ fill: '#f6a200' }} />
          <path d="M355.28,435.89l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M357.76,439.34c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M359.85,437.17c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM363.06,437.17c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M364.95,438.05c0-.53.2-1.06.53-1.5l1.67-2.25.9.61-.96,1.27c.05,0,.12-.02.16-.02,1.08,0,1.87.82,1.87,1.83,0,1.14-.79,2.01-2.09,2.01s-2.08-.84-2.08-1.96ZM367.94,438.04c0-.51-.36-.93-.9-.93s-.89.42-.89.93c0,.54.32.96.89.96s.9-.42.9-.96Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M355.28,332.34l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M357.76,335.8c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M359.85,333.62c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM363.05,333.62c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M364.95,335.82l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M771.27,391.19l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M773.75,394.65c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M775.88,394.5c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M780.77,392.48c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM783.98,392.48c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M434.62,362.2l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M437.1,365.66c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M439.19,363.49c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM442.4,363.49c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M446.94,365.18h-2.59v-.77l2.06-3.84.95.48-1.68,3.13h1.26v-1.2h1.1v1.2h.65v1.01h-.65v1.06h-1.1v-1.06Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M483.63,354.14l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M486.12,357.6c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M488.24,357.61l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M493.14,355.42c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM496.34,355.42c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M419.78,428.46l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M422.26,431.92c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M424.35,429.75c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM427.56,429.75c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M429.56,432.02l.43-.84c.3.24.83.42,1.3.42.76,0,1.12-.42,1.12-.88,0-.59-.54-.88-1.22-.88-.39,0-.77.14-1.01.28l-.42-.38v-2.75h3.51v1.01h-2.37v1.01c.2-.06.42-.09.65-.09,1.1,0,2.04.59,2.04,1.74s-.92,1.92-2.27,1.92c-.76,0-1.35-.23-1.77-.56Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M452.04,441.99l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M454.52,445.44c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M456.61,443.27c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM459.82,443.27c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M465.89,442.39c0,.53-.2,1.06-.53,1.5l-1.67,2.25-.9-.61.96-1.27c-.05,0-.12.02-.16.02-1.08,0-1.87-.82-1.87-1.84,0-1.14.79-2.01,2.09-2.01s2.08.84,2.08,1.96ZM462.9,442.39c0,.51.36.93.9.93s.89-.42.89-.93c0-.54-.32-.96-.89-.96s-.9.42-.9.96Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M515.42,384.17l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M517.9,387.63c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M520.03,387.48c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M529.13,384.57c0,.53-.2,1.06-.53,1.5l-1.67,2.25-.9-.61.96-1.27c-.05,0-.12.02-.16.02-1.08,0-1.87-.82-1.87-1.84,0-1.14.79-2.01,2.09-2.01s2.08.84,2.08,1.96ZM526.15,384.58c0,.51.36.93.9.93s.89-.42.89-.93c0-.54-.32-.96-.89-.96s-.9.42-.9.96Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M561.88,384.17l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M564.37,387.63c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M566.49,387.48c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M571.4,386.6c0-.59.3-1.11.89-1.36-.38-.25-.56-.65-.56-1.14,0-.84.67-1.49,1.79-1.49s1.78.65,1.78,1.49c0,.49-.19.89-.56,1.14.59.25.89.76.89,1.36,0,1-.8,1.7-2.11,1.7s-2.12-.7-2.12-1.7ZM574.42,386.5c0-.45-.31-.83-.91-.83s-.92.37-.92.83.33.8.92.8.91-.35.91-.8ZM574.24,384.24c0-.37-.26-.67-.73-.67s-.73.31-.73.67.24.68.73.68.73-.32.73-.68Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M532.93,428.46l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M535.41,431.92c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M539.33,428.46l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M541.89,432.02l.43-.84c.3.24.83.42,1.3.42.76,0,1.12-.42,1.12-.88,0-.59-.54-.88-1.22-.88-.39,0-.77.14-1.01.28l-.42-.38v-2.75h3.51v1.01h-2.37v1.01c.2-.06.42-.09.65-.09,1.1,0,2.04.59,2.04,1.74s-.92,1.92-2.27,1.92c-.76,0-1.35-.23-1.77-.56Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M134,253.44l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M138.95,253.43c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M141.04,251.25c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM144.25,251.25c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M146.15,253.27c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M62.78,253.44l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M67.73,253.43c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M69.82,251.25c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM73.03,251.25c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#d51317' }}
          />
          <path d="M76.71,249.97l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#d51317' }} />
          <path
            d="M133.89,424.75l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M138.85,424.74c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M140.94,422.56c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM144.14,422.56c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M148.68,424.25h-2.59v-.77l2.07-3.84.95.48-1.68,3.13h1.26v-1.2h1.1v1.2h.65v1.01h-.65v1.06h-1.1v-1.06Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M62.93,424.75l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M67.88,424.74c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M69.97,422.56c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM73.18,422.56c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M75.07,424.75l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M381.26,206.24c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M386.22,206.39c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M390.13,202.93l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M395.23,205.91h-2.59v-.77l2.07-3.84.95.48-1.68,3.13h1.26v-1.2h1.1v1.2h.65v1.01h-.65v1.06h-1.1v-1.06Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M265.16,187.62c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M270.12,187.78c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M272.21,185.6c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM275.41,185.6c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M277.31,186.48c0-.53.2-1.06.53-1.5l1.67-2.25.9.61-.96,1.27c.05,0,.12-.02.16-.02,1.08,0,1.87.82,1.87,1.83,0,1.14-.79,2.01-2.09,2.01s-2.08-.84-2.08-1.96ZM280.29,186.48c0-.51-.36-.93-.9-.93s-.89.42-.89.93c0,.54.32.96.89.96s.9-.42.9-.96Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M289.38,362.81c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M294.34,362.96c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M296.43,360.79c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM299.63,360.79c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M301.5,361.93c0-.59.31-1.11.89-1.36-.37-.25-.56-.65-.56-1.14,0-.84.67-1.49,1.79-1.49s1.78.65,1.78,1.49c0,.49-.19.89-.56,1.14.59.25.89.76.89,1.36,0,1-.8,1.7-2.11,1.7s-2.12-.7-2.12-1.7ZM304.53,361.83c0-.45-.31-.83-.91-.83s-.92.37-.92.83.33.8.92.8.91-.35.91-.8ZM304.35,359.57c0-.37-.26-.67-.73-.67s-.73.31-.73.67.24.68.73.68.73-.32.73-.68Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M221.48,310.23c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M226.43,310.38c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M228.52,308.2c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM231.73,308.2c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M233.62,310.4l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M291.46,276.19c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M296.42,276.35c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M300.34,272.89l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path d="M304.59,272.89l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M291.05,302.57c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M296.01,302.72c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M299.93,299.26l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M302.35,300.55c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM305.55,300.55c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M290.72,328.78c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M295.67,328.93c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M297.76,326.75c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM300.97,326.75c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M307.04,325.87c0,.53-.2,1.06-.53,1.5l-1.67,2.25-.9-.61.96-1.27c-.05,0-.12.02-.16.02-1.08,0-1.87-.82-1.87-1.84,0-1.14.79-2.01,2.09-2.01s2.08.84,2.08,1.96ZM304.05,325.88c0,.51.36.93.9.93s.89-.42.89-.93c0-.54-.32-.96-.89-.96s-.9.42-.9.96Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M290.8,396.24c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M295.75,396.39c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M297.85,394.21c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM301.05,394.21c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M305.75,392.47h-2.9v-1.01h4.22v.71l-2.33,4.79h-1.24l2.25-4.5Z" style={{ fill: '#86b361' }} />
          <path
            d="M292.37,248.41c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M297.33,248.56c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M301.24,245.1l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M303.7,248.41c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M220.61,276.19c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M225.57,276.35c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M227.66,274.17c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM230.86,274.17c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M235.4,275.86h-2.59v-.77l2.07-3.84.95.48-1.68,3.13h1.26v-1.2h1.1v1.2h.65v1.01h-.65v1.06h-1.1v-1.06Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M221.98,248.41c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M226.94,248.56c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M229.03,246.38c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM232.23,246.38c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M234.23,248.66l.43-.84c.3.24.83.42,1.3.42.76,0,1.12-.42,1.12-.88,0-.59-.54-.88-1.22-.88-.39,0-.77.14-1.01.28l-.42-.38v-2.75h3.51v1.01h-2.37v1.01c.2-.06.42-.09.65-.09,1.1,0,2.04.59,2.04,1.74s-.92,1.92-2.27,1.92c-.76,0-1.35-.23-1.77-.56Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M221.07,369.65c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M226.03,369.8c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M228.12,367.63c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM231.32,367.63c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M235.01,366.34l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M221.98,341.86c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M226.94,342.02c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M229.03,339.84c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM232.23,339.84c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M234.13,341.86c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M323.75,248.41c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M328.71,248.56c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M332.62,245.1l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M335.08,248.58l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M431.7,199.43c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M436.65,199.58c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M440.57,196.12l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#86b361' }} />
          <path
            d="M443.13,199.68l.43-.84c.3.24.83.42,1.3.42.76,0,1.12-.42,1.12-.88,0-.59-.54-.88-1.22-.88-.39,0-.77.14-1.01.28l-.42-.38v-2.75h3.51v1.01h-2.37v1.01c.2-.06.42-.09.65-.09,1.1,0,2.04.59,2.04,1.74s-.92,1.92-2.27,1.92c-.76,0-1.35-.23-1.77-.56Z"
            style={{ fill: '#86b361' }}
          />
          <path d="M510.65,281.25l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M513.13,284.71c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M515.25,284.73l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M521.98,281.25l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path d="M421.71,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M424.19,504.76c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M426.29,502.59c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM429.49,502.59c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M431.36,503.73c0-.59.31-1.11.89-1.36-.37-.25-.56-.65-.56-1.14,0-.84.67-1.49,1.79-1.49s1.78.65,1.78,1.49c0,.49-.19.89-.56,1.14.59.25.89.76.89,1.36,0,1-.8,1.7-2.11,1.7s-2.12-.7-2.12-1.7ZM434.39,503.63c0-.45-.31-.83-.91-.83s-.92.37-.92.83.33.8.92.8.91-.35.91-.8ZM434.21,501.37c0-.37-.26-.67-.73-.67s-.73.31-.73.67.24.68.73.68.73-.32.73-.68Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M473.58,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M476.06,504.76c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M479.98,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M482.4,502.59c0-1.67.9-2.84,2.2-2.84s2.19,1.17,2.19,2.84-.9,2.84-2.19,2.84-2.2-1.17-2.2-2.84ZM485.61,502.59c0-1.07-.34-1.82-1-1.82s-1.02.75-1.02,1.82.35,1.82,1.02,1.82,1-.75,1-1.82Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M518.61,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M521.09,504.76c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M525.01,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path d="M529.26,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path d="M541.21,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M543.69,504.76c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M547.61,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M550.06,504.61c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M563.91,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M566.39,504.76c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M570.31,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M572.77,504.78l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M587.44,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M589.92,504.76c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M593.84,501.3l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M598.94,504.28h-2.59v-.77l2.07-3.84.95.48-1.68,3.13h1.26v-1.2h1.1v1.2h.65v1.01h-.65v1.06h-1.1v-1.06Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M573.82,278.59l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M576.31,282.05c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M578.43,282.06l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M583.36,281.89c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M631.03,309.67l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M633.52,313.13c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M635.64,312.98c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M643.37,309.21h-2.9v-1.01h4.22v.71l-2.33,4.79h-1.24l2.25-4.5Z" style={{ fill: '#f6a200' }} />
          <path d="M690.02,307.67l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M692.5,311.13c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M694.62,310.98c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M702.2,310.65h-2.59v-.77l2.07-3.84.95.48-1.68,3.13h1.26v-1.2h1.1v1.2h.65v1.01h-.65v1.06h-1.1v-1.06Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M726.21,307.67l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M728.7,311.13c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M730.82,310.98c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M735.75,311.15l.48-.78c.36.32.86.44,1.31.44.71,0,1.2-.36,1.2-.92,0-.43-.4-.79-1.09-.79-.28,0-.51.04-.78.2l-.38-.67,1.67-1.41h-2.12v-1.01h3.59v.98l-1.31,1.15c.15,0,.3.04.44.08.68.2,1.17.74,1.17,1.52,0,1.25-1.04,1.86-2.28,1.86-.8,0-1.46-.25-1.9-.65Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M692.22,478.15l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M694.7,481.61c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M698.62,478.15l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M701.05,480.58c0-.59.31-1.11.89-1.36-.37-.25-.56-.65-.56-1.14,0-.84.67-1.49,1.79-1.49s1.78.65,1.78,1.49c0,.49-.19.89-.56,1.14.59.25.89.76.89,1.36,0,1-.8,1.7-2.11,1.7s-2.12-.7-2.12-1.7ZM704.08,480.48c0-.45-.31-.83-.91-.83s-.92.37-.92.83.33.8.92.8.91-.35.91-.8ZM703.9,478.22c0-.37-.26-.67-.73-.67s-.73.31-.73.67.24.68.73.68.73-.32.73-.68Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M727.29,478.15l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M729.78,481.61c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M733.69,478.15l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M740.32,478.55c0,.53-.2,1.06-.53,1.5l-1.67,2.25-.9-.61.96-1.27c-.05,0-.12.02-.16.02-1.08,0-1.87-.82-1.87-1.84,0-1.14.79-2.01,2.09-2.01s2.08.84,2.08,1.96ZM737.34,478.56c0,.51.36.93.9.93s.89-.42.89-.93c0-.54-.32-.96-.89-.96s-.9.42-.9.96Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M771.57,337.91l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M774.05,341.37c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M776.18,341.22c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M781.11,341.22c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M643.48,406.86l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M645.96,410.32c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M649.88,406.86l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path d="M655.14,406.4h-2.9v-1.01h4.22v.71l-2.33,4.79h-1.24l2.25-4.5Z" style={{ fill: '#f6a200' }} />
          <path d="M643.06,384.17l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M645.54,387.63c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M647.67,387.48c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M652.6,386.34c0-.53.2-1.06.53-1.5l1.67-2.25.9.61-.96,1.27c.05,0,.12-.02.16-.02,1.08,0,1.87.82,1.87,1.83,0,1.14-.79,2.01-2.09,2.01s-2.08-.84-2.08-1.96ZM655.58,386.33c0-.51-.36-.93-.9-.93s-.89.42-.89.93c0,.54.32.96.89.96s.9-.42.9-.96Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M688.81,406.86l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M691.29,410.32c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M693.42,410.17c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M700.14,406.86l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path d="M688.47,384.17l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M690.95,387.63c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M693.08,387.48c1.86-1.5,2.71-2.38,2.71-3.09,0-.4-.26-.74-.81-.74-.35,0-.79.17-1.24.58l-.58-.75c.67-.73,1.5-.86,1.91-.86,1.05,0,1.91.58,1.91,1.67s-1.05,2.15-2.07,2.92h2.24v1.01h-4.08v-.73Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M698.11,387.73l.43-.84c.3.24.83.42,1.3.42.76,0,1.12-.42,1.12-.88,0-.59-.54-.88-1.22-.88-.39,0-.77.14-1.01.28l-.42-.38v-2.75h3.51v1.01h-2.37v1.01c.2-.06.42-.09.65-.09,1.1,0,2.04.59,2.04,1.74s-.92,1.92-2.27,1.92c-.76,0-1.35-.23-1.77-.56Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M649.54,471.01l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M652.02,474.47c0-.38.3-.67.67-.67s.68.29.68.67-.3.66-.68.66-.67-.3-.67-.66Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M655.94,471.01l-1.19.77-.51-.8,1.82-1.45h1.02v5.51h-1.14v-4.04Z" style={{ fill: '#f6a200' }} />
          <path
            d="M658.39,473.17c0-.53.2-1.06.53-1.5l1.67-2.25.9.61-.96,1.27c.05,0,.12-.02.16-.02,1.08,0,1.87.82,1.87,1.83,0,1.14-.79,2.01-2.09,2.01s-2.08-.84-2.08-1.96ZM661.38,473.17c0-.51-.36-.93-.9-.93s-.89.42-.89.93c0,.54.32.96.89.96s.9-.42.9-.96Z"
            style={{ fill: '#f6a200' }}
          />
        </g>
        <g id="Saal">
          <rect
            x="536.16"
            y="167.19"
            width="28.35"
            height="56.69"
            style={{
              fill: 'rgba(208,212,215,.4)',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
            }}
          />
          <g style={{ opacity: '.5' }}>
            <path
              d="M548.8,219.98c-1.66,0-3-1.28-3-3.08,0-.8.27-1.52.74-2.04l.85.77c-.34.4-.44.8-.44,1.2,0,1.06.83,1.84,1.85,1.84s1.88-.76,1.88-1.79c0-.54-.21-1.04-.6-1.4l.88-.78c.54.56.86,1.31.86,2.21,0,1.8-1.34,3.08-3.02,3.08Z"
              style={{ fill: '#1d1d1b' }}
            />
            <path
              d="M545.89,211.94v-1.24l5.83-2.54v1.28l-.93.4v2.96l.93.4v1.29l-5.83-2.54ZM549.81,210.23l-1.67.67c-.44.18-1.03.41-1.04.41.02,0,.6.23,1.04.41l1.67.68v-2.18Z"
              style={{ fill: '#1d1d1b' }}
            />
            <path d="M545.89,207.5v-4.37h1.08v3.11h1.19v-2.65h1.07v2.65h2.49v1.26h-5.83Z" style={{ fill: '#1d1d1b' }} />
            <path
              d="M545.89,202.46v-4.56h1.08v3.3h1.19v-3.13h1.07v3.13h1.41v-3.43h1.08v4.69h-5.83Z"
              style={{ fill: '#1d1d1b' }}
            />
            <path d="M546.97,195.39v1.98h-1.08v-5.22h1.08v1.98h4.75v1.26h-4.75Z" style={{ fill: '#1d1d1b' }} />
            <path
              d="M545.89,191.39v-4.56h1.08v3.3h1.19v-3.13h1.07v3.13h1.41v-3.43h1.08v4.69h-5.83Z"
              style={{ fill: '#1d1d1b' }}
            />
            <path
              d="M545.89,185.92v-2.5c0-1.72,1.01-2.62,2.26-2.62.75,0,1.47.39,1.85,1.11l1.72-1.21v1.45l-1.37.9c.03.17.04.32.04.5v1.12h1.32v1.26h-5.83ZM549.32,183.38c0-.74-.53-1.25-1.17-1.25s-1.19.51-1.19,1.24v1.29h2.36v-1.28Z"
              style={{ fill: '#1d1d1b' }}
            />
            <path d="M545.89,179.89v-1.26h5.83v1.26h-5.83Z" style={{ fill: '#1d1d1b' }} />
            <path
              d="M545.89,175.41v-1.24l5.83-2.54v1.28l-.93.4v2.96l.93.4v1.29l-5.83-2.54ZM549.81,173.7l-1.67.67c-.44.18-1.03.41-1.04.41.02,0,.6.23,1.04.41l1.67.67v-2.18Z"
              style={{ fill: '#1d1d1b' }}
            />
          </g>
          <line
            x1="501.5"
            y1="262.57"
            x2="531.04"
            y2="262.57"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '8.05px',
            }}
          />
          <line
            x1="228.62"
            y1="234.53"
            x2="214.32"
            y2="234.53"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="228.62"
            y1="260.59"
            x2="214.32"
            y2="260.59"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="228.62"
            y1="286.8"
            x2="214.32"
            y2="286.8"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="228.62"
            y1="328.07"
            x2="214.32"
            y2="328.07"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="228.62"
            y1="354.37"
            x2="214.32"
            y2="354.37"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="228.62"
            y1="380.11"
            x2="214.32"
            y2="380.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="561.72"
            y1="264.84"
            x2="561.72"
            y2="281.03"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="597.84"
            y1="264.84"
            x2="597.84"
            y2="281.03"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="670.75"
            y1="399.44"
            x2="656.44"
            y2="399.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="684.5"
            y1="396.54"
            x2="670.2"
            y2="396.54"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="670.75"
            y1="454.43"
            x2="656.44"
            y2="454.43"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="313.18"
            y1="339.64"
            x2="298.88"
            y2="339.64"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="313.18"
            y1="406.99"
            x2="298.88"
            y2="406.99"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="313.18"
            y1="380.56"
            x2="298.88"
            y2="380.56"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="316.2"
            y1="245.7"
            x2="316.2"
            y2={260}
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="347.43"
            y1="245.7"
            x2="347.43"
            y2={260}
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="229.52"
            y1="170.75"
            x2="229.52"
            y2="185.05"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="321.41"
            y1="170.75"
            x2="321.41"
            y2="185.05"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <rect x="143.48" y="505.43" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect x="95.65" y="505.43" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect x="47.82" y="505.43" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="505.43" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect x="143.48" y="125.88" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect x="95.65" y="125.87" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect x="47.82" y="125.88" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="125.88" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="173.32" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="220.76" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="268.21" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="315.65" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="363.1" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="410.54" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <rect y="457.99" width="7.94" height="7.94" style={{ fill: '#d0d4d7' }} />
          <path
            d="M151.98,355.96h22.68v-21.83h-22.68v21.83ZM163.38,353.3c-4.53,0-8.19-3.63-8.19-8.11s3.67-8.11,8.19-8.11,8.19,3.63,8.19,8.11-3.67,8.11-8.19,8.11"
            style={{ fill: '#1951a0' }}
          />
          <path
            d="M164.7,349.31v-6.57h-4.05v1.98h1.28v4.61h-1.29v1.96h5.36v-1.98h-1.3ZM163.32,341.81c.92,0,1.55-.59,1.55-1.46,0-.93-.59-1.56-1.47-1.56-.91,0-1.57.61-1.57,1.46,0,.9.62,1.55,1.49,1.55Z"
            style={{ fill: '#1d1d1b', fillRule: 'evenodd' }}
          />
          <polygon points="140.51 313.95 149.01 319.62 140.51 325.29 140.51 313.95" style={{ fill: '#d51317' }} />
          <polyline
            points="309.56 111.9 343.58 111.9 343.58 168.82 216.73 168.82"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <polygon points="295.33 94.32 289.66 102.83 283.99 94.32 295.33 94.32" style={{ fill: '#d51317' }} />
          <polygon points="534.83 270.68 540.5 262.17 546.17 270.68 534.83 270.68" style={{ fill: '#d51317' }} />
          <polygon points="296.08 480.74 290.41 489.25 284.75 480.74 296.08 480.74" style={{ fill: '#d51317' }} />
          <line
            x1="512.82"
            y1="504.14"
            x2="512.82"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="445.81"
            y1="504.14"
            x2="445.81"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="535.76"
            y1="504.14"
            x2="535.76"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="558.3"
            y1="504.14"
            x2="558.3"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="581.13"
            y1="504.14"
            x2="581.13"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="603.97"
            y1="504.14"
            x2="603.97"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="409.67"
            y1="504.14"
            x2="409.67"
            y2="518.44"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="360.58"
            y1="297.69"
            x2="346.28"
            y2="297.69"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="360.58"
            y1="369.98"
            x2="346.28"
            y2="369.98"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="360.58"
            y1="418.85"
            x2="346.28"
            y2="418.85"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="360.58"
            y1="455.76"
            x2="346.28"
            y2="455.76"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="434.87"
            y1="262.75"
            x2="434.87"
            y2="277.05"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="382.47"
            y1="262.75"
            x2="382.47"
            y2="277.05"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="471.01"
            y1="259.52"
            x2="471.01"
            y2="277.05"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="360.58"
            y1="492.56"
            x2="346.28"
            y2="492.56"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="474.57"
            y1="327.55"
            x2="474.57"
            y2="463.42"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="409.31"
            y1="399.84"
            x2="599.36"
            y2="399.84"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="481.73"
            y1="327.55"
            x2="467.42"
            y2="327.55"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="472.6"
            y1="426.95"
            x2="441.12"
            y2="426.95"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="409.31"
            y1="392.69"
            x2="409.31"
            y2="406.99"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="441.56"
            y1="398.35"
            x2="441.56"
            y2="406.99"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="599.36"
            y1="392.69"
            x2="599.36"
            y2="406.99"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="539.11"
            y1="391.57"
            x2="539.11"
            y2="400.14"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="503.18"
            y1="391.57"
            x2="503.18"
            y2="400.14"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="473.16"
            y1="371.19"
            x2="481.73"
            y2="371.19"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="473.16"
            y1="342.82"
            x2="481.73"
            y2="342.82"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <rect x="210.13" y="278.56" width="7.91" height="26.78" style={{ fill: '#fff' }} />
          <polyline
            points="791.21 264.17 791.21 517.74 218.02 517.96 218.02 305.34 210.18 305.34 210.18 525.8 799.05 525.72 799.1 264.17 791.21 264.17"
            style={{ fill: '#d0d4d7' }}
          />
          <polygon points="210.18 0 210.18 309.46 218.02 309.46 218.02 1.63 210.18 0" style={{ fill: '#d0d4d7' }} />
          <polyline
            points="618.76 288.73 618.76 297.51 670.2 297.51 670.2 493.47 618.76 493.47 618.76 502.32"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '7.84px',
            }}
          />
          <polyline
            points="549.97 262.72 618.76 262.72 618.76 272.03"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '7.84px',
            }}
          />
          <line
            x1="346.76"
            y1="261.39"
            x2={473}
            y2="261.39"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <polyline
            points="311.73 381.11 311.73 261.39 346.76 261.39"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="311.73"
            y1="521.88"
            x2="311.73"
            y2="380.46"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="214.32"
            y1="4.85"
            x2="570.04"
            y2="83.1"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '7.84px',
            }}
          />
          <polyline
            points="313.06 416.55 269.7 416.55 269.7 479.75"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="344.74"
            y1="421.94"
            x2="269.7"
            y2="421.94"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="425.32"
            x2="269.7"
            y2="425.32"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="428.69"
            x2="269.7"
            y2="428.69"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="432.06"
            x2="269.7"
            y2="432.06"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="435.43"
            x2="269.7"
            y2="435.43"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="438.81"
            x2="269.7"
            y2="438.81"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="442.18"
            x2="269.7"
            y2="442.18"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="445.56"
            x2="269.7"
            y2="445.56"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="448.93"
            x2="269.7"
            y2="448.93"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="452.3"
            x2="269.7"
            y2="452.3"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="455.68"
            x2="269.7"
            y2="455.68"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="459.05"
            x2="269.7"
            y2="459.05"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="462.42"
            x2="269.7"
            y2="462.42"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="465.8"
            x2="269.7"
            y2="465.8"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="469.17"
            x2="269.7"
            y2="469.17"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="472.54"
            x2="269.7"
            y2="472.54"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="475.92"
            x2="269.7"
            y2="475.92"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="310.44"
            y1="109.66"
            x2="267.95"
            y2="109.66"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="113.04"
            x2="267.95"
            y2="113.04"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="116.41"
            x2="267.95"
            y2="116.41"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="119.78"
            x2="267.95"
            y2="119.78"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="123.16"
            x2="267.95"
            y2="123.16"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="126.53"
            x2="267.95"
            y2="126.53"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="129.9"
            x2="267.95"
            y2="129.9"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="133.28"
            x2="267.95"
            y2="133.28"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="136.65"
            x2="267.95"
            y2="136.65"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="140.02"
            x2="267.95"
            y2="140.02"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="143.4"
            x2="267.95"
            y2="143.4"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="146.77"
            x2="267.95"
            y2="146.77"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="150.14"
            x2="267.95"
            y2="150.14"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="153.52"
            x2="267.95"
            y2="153.52"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="156.89"
            x2="267.95"
            y2="156.89"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="160.26"
            x2="267.95"
            y2="160.26"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <line
            x1="310.44"
            y1="163.64"
            x2="267.94"
            y2="163.64"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.98px',
            }}
          />
          <polyline
            points="344.74 408.65 344.74 492.49 313.06 492.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <polyline
            points="344.74 411.08 344.74 297.67 313.06 297.67"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <polyline
            points="310.44 105.79 310.44 168.24 269.7 168.24 269.7 105.79"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '3.92px',
            }}
          />
          <rect
            x="361.54"
            y="111.9"
            width="111.96"
            height="57.05"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '3.92px',
            }}
          />
          <line
            x1="566.67"
            y1="80.19"
            x2="566.67"
            y2="262.72"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '7.84px',
            }}
          />
          <line
            x1="404.16"
            y1="168.83"
            x2="404.16"
            y2="224.78"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '3.92px',
            }}
          />
          <line
            x1="374.62"
            y1="168.83"
            x2="374.62"
            y2="185.28"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '3.92px',
            }}
          />
          <line
            x1="404.16"
            y1="185.28"
            x2="372.66"
            y2="185.28"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '3.92px',
            }}
          />
          <line
            x1="618.76"
            y1="268.11"
            x2="798.82"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '7.84px',
            }}
          />
          <line
            x1="311.36"
            y1="261.43"
            x2="300.33"
            y2="261.43"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="311.36"
            y1="286.97"
            x2="300.33"
            y2="286.97"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="311.36"
            y1="313.18"
            x2="300.33"
            y2="313.18"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="473.42"
            y1="168.83"
            x2="473.42"
            y2="185.28"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '3.92px',
            }}
          />
          <line
            x1="344.73"
            y1="492.49"
            x2="344.73"
            y2="521.93"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="664.73"
            y1="370.99"
            x2="664.73"
            y2="349.6"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="662.42"
            y1="370.99"
            x2="662.42"
            y2="349.6"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="660.11"
            y1="370.99"
            x2="660.11"
            y2="349.6"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="657.8"
            y1="370.99"
            x2="657.8"
            y2="349.6"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="655.5"
            y1="370.99"
            x2="655.5"
            y2="349.6"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="653.19"
            y1="370.99"
            x2="653.19"
            y2="349.6"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="664.73"
            y1="442.89"
            x2="664.73"
            y2="421.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="662.42"
            y1="442.89"
            x2="662.42"
            y2="421.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="660.11"
            y1="442.89"
            x2="660.11"
            y2="421.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="657.8"
            y1="442.89"
            x2="657.8"
            y2="421.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="655.5"
            y1="442.89"
            x2="655.5"
            y2="421.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="653.19"
            y1="442.89"
            x2="653.19"
            y2="421.49"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="656.22"
            y1="522.86"
            x2="656.22"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="653.91"
            y1="522.86"
            x2="653.91"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="651.6"
            y1="522.86"
            x2="651.6"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="649.3"
            y1="522.86"
            x2="649.3"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="646.99"
            y1="522.86"
            x2="646.99"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="644.68"
            y1="522.86"
            x2="644.68"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="642.37"
            y1="522.86"
            x2="642.37"
            y2="493.47"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="656.22"
            y1="297.5"
            x2="656.22"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="653.91"
            y1="297.5"
            x2="653.91"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="651.6"
            y1="297.5"
            x2="651.6"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="649.3"
            y1="297.5"
            x2="649.3"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="646.99"
            y1="297.5"
            x2="646.99"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="644.68"
            y1="297.5"
            x2="644.68"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="642.37"
            y1="297.5"
            x2="642.37"
            y2="268.11"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: '.85px',
            }}
          />
          <line
            x1="345.55"
            y1="304.46"
            x2="313.06"
            y2="304.46"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="307.83"
            x2="313.06"
            y2="307.83"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="311.2"
            x2="313.06"
            y2="311.2"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="314.58"
            x2="313.06"
            y2="314.58"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="317.95"
            x2="313.06"
            y2="317.95"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="321.32"
            x2="313.06"
            y2="321.32"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="324.7"
            x2="313.06"
            y2="324.7"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="328.07"
            x2="313.06"
            y2="328.07"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="331.45"
            x2="313.06"
            y2="331.45"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="334.82"
            x2="313.06"
            y2="334.82"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="338.19"
            x2="313.06"
            y2="338.19"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="341.57"
            x2="313.06"
            y2="341.57"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="344.94"
            x2="313.06"
            y2="344.94"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="348.31"
            x2="313.06"
            y2="348.31"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="351.69"
            x2="313.06"
            y2="351.69"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="345.55"
            y1="355.06"
            x2="313.06"
            y2="355.06"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="479.29"
            x2="312.35"
            y2="479.29"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="482.67"
            x2="312.35"
            y2="482.67"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <line
            x1="344.74"
            y1="486.04"
            x2="312.35"
            y2="486.04"
            style={{ fill: 'none', stroke: '#d0d4d7', strokeMiterlimit: 10 }}
          />
          <polygon points="440.01 94.25 432.67 87.13 442.06 83.1 440.01 94.25" style={{ fill: '#d51317' }} />
          <line
            x1="290.41"
            y1="493.09"
            x2="290.41"
            y2="494.59"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="290.41"
            y1="497.2"
            x2="290.41"
            y2="501.12"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '2.61 2.61',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <polyline
            points="290.41 502.43 290.41 503.93 288.91 503.93"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="285.9"
            y1="503.93"
            x2="257.24"
            y2="503.93"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '3.02 3.02',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <polyline
            points="255.73 503.93 254.23 503.93 254.23 502.43"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="254.23"
            y1="499.41"
            x2="254.23"
            y2="223.1"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '3.02 3.02',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <polyline
            points="254.23 221.59 254.23 220.09 255.73 220.09"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="258.68"
            y1="220.09"
            x2="274.93"
            y2="220.09"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '2.95 2.95',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="276.4"
            y1="220.09"
            x2="277.9"
            y2="220.09"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <polyline
            points="342.49 220.09 359.69 220.09 359.69 242.97 483.26 242.97 483.26 309.46 391.36 309.46 391.36 475.04 625.35 475.04 625.35 432.23 645.66 432.23"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: 3,
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <polyline
            points="679.88 432.24 745.18 432.24 745.18 362.33 679.88 362.33"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '2.96',
              strokeMiterlimit: 10,
              strokeWidth: '.49px',
            }}
          />
          <line
            x1="540.56"
            y1="274.42"
            x2="540.56"
            y2="326.08"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '3.11',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="645.66"
            y1="362.33"
            x2="540.12"
            y2="362.33"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: 3,
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <polyline
            points="540.5 258.47 540.5 239.06 517.54 239.06 517.54 152.27 540.5 152.27 540.5 105.13 289.64 64.65 289.59 93.19"
            style={{
              fill: 'none',
              stroke: '#d51317',
              strokeDasharray: '3.12',
              strokeMiterlimit: 10,
              strokeWidth: '.5px',
            }}
          />
          <line
            x1="714.82"
            y1="292.37"
            x2="714.82"
            y2="304.43"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="678.67"
            y1="291.72"
            x2="678.67"
            y2="304.43"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="750.89"
            y1="291.78"
            x2="750.89"
            y2="304.43"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="750.89"
            y1="293.75"
            x2="677.01"
            y2="293.75"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="715.08"
            y1="496.52"
            x2="715.08"
            y2="484.46"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="751.22"
            y1="497.17"
            x2="751.22"
            y2="484.46"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="679.01"
            y1="497.11"
            x2="679.01"
            y2="484.46"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <line
            x1="679.01"
            y1="495.14"
            x2="752.89"
            y2="495.14"
            style={{
              fill: 'none',
              stroke: '#d0d4d7',
              strokeMiterlimit: 10,
              strokeWidth: 4,
            }}
          />
          <path
            d="M59.41,175.51l2.05-3.31c1.51,1.37,3.63,1.87,5.53,1.87,3.02,0,5.07-1.51,5.07-3.88,0-1.83-1.69-3.34-4.6-3.34-1.19,0-2.16.18-3.31.83l-1.62-2.84,7.04-5.96h-8.98v-4.28h15.17v4.13l-5.53,4.85c.65.04,1.26.18,1.87.36,2.88.83,4.96,3.13,4.96,6.43,0,5.28-4.38,7.87-9.63,7.87-3.38,0-6.18-1.04-8.01-2.73Z"
            style={{ fill: '#d51317' }}
          />
          <path d="M83.28,168.45h2.1v7.92h5.62v1.8h-7.72v-9.72Z" style={{ fill: '#d51317' }} />
          <path
            d="M92.04,168.57c0-.66.54-1.2,1.18-1.2s1.2.54,1.2,1.2-.54,1.18-1.2,1.18-1.18-.54-1.18-1.18ZM92.24,170.67h2.01v7.5h-2.01v-7.5Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M95.78,170.67h1.96v1c.6-.82,1.46-1.15,2.37-1.15,1.98,0,2.67,1.27,2.67,3.33v4.32h-2.01v-4.06c0-1.1-.29-1.68-1.3-1.68s-1.68.81-1.68,1.95v3.79h-2.01v-7.5Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M103.98,174.42c0-2.16,1.35-3.9,3.42-3.9.87,0,1.72.34,2.26.99v-3.21h2.01v9.87h-1.91v-.9c-.54.72-1.5,1.05-2.4,1.05-2.07,0-3.39-1.74-3.39-3.9ZM109.67,174.42c0-1.17-.75-2.04-1.78-2.04s-1.86.87-1.86,2.04.78,2.04,1.86,2.04,1.78-.84,1.78-2.04Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M112.98,174.42c0-2.16,1.32-3.9,3.39-3.9.87,0,1.8.34,2.34,1.06v-.92h1.97v7.5h-1.97v-.9c-.52.72-1.47,1.05-2.34,1.05-2.07,0-3.39-1.74-3.39-3.9ZM118.67,174.42c0-1.17-.75-2.04-1.78-2.04s-1.86.87-1.86,2.04.78,2.04,1.86,2.04,1.78-.84,1.78-2.04Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M122.21,168.3h2.01v3.31c.58-.78,1.41-1.09,2.31-1.09,1.95,0,2.67,1.23,2.67,3.33v4.32h-2.01v-4.06c0-1.1-.33-1.68-1.32-1.68-1.07,0-1.65.86-1.65,1.94v3.81h-2.01v-9.87Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M130.41,174.42c0-2.17,1.77-3.9,4-3.9s4.02,1.72,4.02,3.9-1.77,3.9-4.02,3.9-4-1.73-4-3.9ZM136.38,174.42c0-1.17-.84-2.04-1.96-2.04s-1.96.87-1.96,2.04.84,2.04,1.96,2.04,1.96-.85,1.96-2.04Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M140.06,172.31h-1.17v-1.62h1.17v-.5c0-1.74.96-2.49,2.24-2.49.67,0,1.42.2,1.95.46l-.57,1.49c-.17-.08-.5-.23-.84-.23-.42,0-.77.21-.77.99v.27h1.88v1.62h-1.88v5.87h-2.01v-5.87Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M70.6,315.55h7.6v1.8h-5.5v1.98h5.22v1.78h-5.22v2.36h5.71v1.8h-7.81v-9.72Z"
            style={{ fill: '#d51317' }}
          />
          <path d="M79.72,315.55h2.1v9.72h-2.1v-9.72Z" style={{ fill: '#d51317' }} />
          <path
            d="M83.75,315.55h2.01l3.35,4.62c.54.73,1.15,1.79,1.15,1.79v-6.41h2.1v9.72h-2.03l-3.24-4.44c-.64-.87-1.24-1.91-1.24-1.91v6.34h-2.1v-9.72Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M93.94,320.41c0-2.78,2.13-5.01,5.13-5.01,1.37,0,2.57.48,3.45,1.24l-1.3,1.42c-.67-.6-1.37-.76-2.1-.76-1.69,0-2.98,1.38-2.98,3.11s1.26,3.12,2.98,3.12c.93,0,1.71-.42,2.24-.97v-1.42h-1.65v-1.53h3.57v3.78c-.85,1.27-2.43,2.05-4.18,2.05-3,0-5.15-2.24-5.15-5.02Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M108.56,315.55h2.07l4.23,9.72h-2.13l-.66-1.54h-4.93l-.66,1.54h-2.14l4.23-9.72ZM111.41,322.09l-1.12-2.78c-.3-.73-.68-1.71-.69-1.74-.01.03-.39,1-.69,1.74l-1.12,2.78h3.63Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M115.97,315.55h2.01l3.35,4.62c.54.73,1.15,1.79,1.15,1.79v-6.41h2.1v9.72h-2.03l-3.24-4.44c-.64-.87-1.24-1.91-1.24-1.91v6.34h-2.1v-9.72Z"
            style={{ fill: '#d51317' }}
          />
          <path
            d="M126.16,320.41c0-2.78,2.13-5.01,5.13-5.01,1.37,0,2.57.48,3.45,1.24l-1.3,1.42c-.67-.6-1.37-.76-2.1-.76-1.69,0-2.98,1.38-2.98,3.11s1.26,3.12,2.98,3.12c.93,0,1.71-.42,2.24-.97v-1.42h-1.65v-1.53h3.57v3.78c-.85,1.27-2.43,2.05-4.18,2.05-3,0-5.15-2.24-5.15-5.02Z"
            style={{ fill: '#d51317' }}
          />
          <path d="M300.11,218.87h7.28v1.8h-5.17v1.98h4.41v1.78h-4.41v4.16h-2.1v-9.72Z" style={{ fill: '#86b361' }} />
          <path
            d="M308.09,224.83c0-2.17,1.77-3.9,4-3.9s4.02,1.72,4.02,3.9-1.77,3.9-4.02,3.9-4-1.73-4-3.9ZM314.06,224.83c0-1.17-.84-2.04-1.96-2.04s-1.96.87-1.96,2.04.84,2.04,1.96,2.04,1.96-.85,1.96-2.04Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M318.18,228.96c.93,0,1.27-.32,1.54-.9l.17-.33-3.13-6.65h2.04l1.51,3.51c.22.53.5,1.28.5,1.28,0,0,.31-.88.48-1.32l1.37-3.46h2.01l-3.33,7.71c-.81,1.85-2.22,2-3.15,2v-1.83Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M325.31,224.83c0-2.16,1.68-3.9,3.88-3.9,2.04,0,3.64,1.74,3.64,3.87,0,.36-.04.72-.04.72h-5.44c.24,1.03,1.09,1.53,2.08,1.53.72,0,1.47-.21,2.02-.58l.75,1.44c-.84.55-1.81.83-2.79.83-2.19,0-4.11-1.43-4.11-3.9ZM330.95,224.19c-.15-.95-.9-1.58-1.82-1.58s-1.62.63-1.81,1.58h3.63Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M334.16,221.08h1.98v1.44c.21-.62.97-1.59,2.62-1.59v2.19c-1.74,0-2.59.79-2.59,2.58v2.88h-2.01v-7.5Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M278.85,225.49c7.87-6.36,11.46-10.06,11.46-13.08,0-1.69-1.11-3.13-3.41-3.13-1.47,0-3.34.72-5.25,2.44l-2.44-3.16c2.84-3.09,6.32-3.63,8.08-3.63,4.42,0,8.09,2.44,8.09,7.04s-4.46,9.09-8.77,12.33h9.49v4.28h-17.25v-3.09Z"
            style={{ fill: '#86b361' }}
          />
          <path
            d="M553.66,335.6c0-2.78,2.13-5.01,5.13-5.01,1.37,0,2.57.48,3.45,1.24l-1.3,1.42c-.67-.6-1.37-.76-2.1-.76-1.69,0-2.98,1.38-2.98,3.11s1.26,3.12,2.98,3.12c.93,0,1.71-.42,2.24-.97v-1.42h-1.65v-1.53h3.57v3.78c-.85,1.27-2.43,2.05-4.18,2.05-3,0-5.15-2.24-5.15-5.02Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M564.67,332.96h1.98v1.44c.21-.62.97-1.59,2.62-1.59v2.19c-1.74,0-2.59.79-2.59,2.58v2.88h-2.01v-7.5Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M570.15,336.71c0-2.17,1.77-3.9,4-3.9s4.02,1.72,4.02,3.9-1.77,3.9-4.02,3.9-4-1.73-4-3.9ZM576.12,336.71c0-1.17-.84-2.04-1.96-2.04s-1.96.87-1.96,2.04.84,2.04,1.96,2.04,1.96-.85,1.96-2.04Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M579.09,339.59l.72-1.54c.51.39,1.22.79,2.34.79.46,0,1.23-.18,1.23-.63s-.64-.55-1.29-.74c-1.48-.39-2.7-.88-2.7-2.32,0-1.53,1.5-2.34,2.91-2.34,1.23,0,2.01.3,2.79.75l-.67,1.5c-.74-.36-1.42-.53-2.08-.53-.46,0-.9.21-.9.53,0,.42.63.55,1.32.71,1.27.3,2.67.85,2.67,2.38,0,1.84-1.88,2.46-3.27,2.46-1.61,0-2.64-.63-3.06-1.02Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M586.23,339.59l.72-1.54c.51.39,1.21.79,2.34.79.46,0,1.23-.18,1.23-.63s-.65-.55-1.29-.74c-1.49-.39-2.7-.88-2.7-2.32,0-1.53,1.5-2.34,2.91-2.34,1.23,0,2.01.3,2.79.75l-.67,1.5c-.73-.36-1.42-.53-2.08-.53-.46,0-.9.21-.9.53,0,.42.63.55,1.32.71,1.28.3,2.67.85,2.67,2.38,0,1.84-1.88,2.46-3.27,2.46-1.6,0-2.64-.63-3.06-1.02Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M593.53,336.71c0-2.16,1.68-3.9,3.88-3.9,2.04,0,3.65,1.74,3.65,3.87,0,.36-.04.72-.04.72h-5.45c.24,1.03,1.09,1.53,2.08,1.53.72,0,1.47-.21,2.03-.58l.75,1.44c-.84.55-1.82.83-2.79.83-2.19,0-4.11-1.43-4.11-3.9ZM599.17,336.06c-.15-.95-.9-1.58-1.81-1.58s-1.62.63-1.82,1.58h3.63Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M602.38,332.96h1.98v1.44c.21-.62.97-1.59,2.62-1.59v2.19c-1.74,0-2.59.79-2.59,2.58v2.88h-2.01v-7.5Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M553.63,352.3l.76-1.62c.78.57,1.71,1.06,3.2,1.06.81,0,1.8-.38,1.8-1.14s-.87-.95-1.73-1.16c-1.81-.42-3.69-1.04-3.69-3.06,0-1.88,1.75-2.8,3.62-2.8,1.47,0,2.58.39,3.55,1.03l-.77,1.62c-.78-.51-1.8-.78-2.79-.78-.51,0-1.47.2-1.47.93,0,.63.71.85,1.67,1.08,1.73.4,3.79,1.05,3.79,3.13s-2.04,3.01-3.99,3.01c-2.33,0-3.42-.88-3.96-1.32Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M562.78,349.71c0-2.16,1.32-3.9,3.39-3.9.87,0,1.8.34,2.34,1.06v-.92h1.97v7.5h-1.97v-.9c-.52.72-1.47,1.05-2.34,1.05-2.07,0-3.39-1.74-3.39-3.9ZM568.47,349.71c0-1.17-.75-2.04-1.78-2.04s-1.86.87-1.86,2.04.78,2.04,1.86,2.04,1.78-.84,1.78-2.04Z"
            style={{ fill: '#f6a200' }}
          />
          <path
            d="M571.78,349.71c0-2.16,1.32-3.9,3.39-3.9.87,0,1.8.34,2.34,1.06v-.92h1.97v7.5h-1.97v-.9c-.52.72-1.47,1.05-2.34,1.05-2.07,0-3.39-1.74-3.39-3.9ZM577.47,349.71c0-1.17-.75-2.04-1.78-2.04s-1.86.87-1.86,2.04.78,2.04,1.86,2.04,1.78-.84,1.78-2.04Z"
            style={{ fill: '#f6a200' }}
          />
          <path d="M581.01,343.59h2.01v9.87h-2.01v-9.87Z" style={{ fill: '#f6a200' }} />
          <path
            d="M542.01,336.65l-5.03,3.27-2.16-3.38,7.69-6.11h4.31v23.29h-4.82v-17.07Z"
            style={{ fill: '#f6a200' }}
          />
        </g>
      </svg>
    </div>
  )
}
