import { Container, Title } from '~/components'
import { PageTitleBlockStoryblok } from '~/storyblok'
import * as styles from './PageTitleBlock.css'

type Props = {
  blok: PageTitleBlockStoryblok
  block: PageTitleBlockStoryblok
}

export function PageTitleBlock({ blok }: Props) {
  return (
    <div css={styles.wrapper}>
      <Container width="small">
        <Title label={blok.label}>{blok.text}</Title>
      </Container>
    </div>
  )
}
