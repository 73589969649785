import { css } from '@emotion/react'
import { breakpoints } from '~/base'

export const item = css`
  margin-bottom: 96px;

  @media ${breakpoints.large} {
    margin-bottom: 200px;
  }
`
