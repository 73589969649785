import { css } from '@emotion/react'
import { font } from '~/base'

export const wrapper = css`
  width: 100%;
  position: relative;

  svg {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%;
  }

  path[data-active='true'],
  rect[data-active='true'] {
    cursor: pointer;
    transition: fill-opacity 0.25s ease;
  }

  path[data-active='false'],
  rect[data-active='false'] {
    cursor: not-allowed;
  }

  path[data-active='true']:hover,
  rect[data-active='true']:hover {
    fill-opacity: 0.6;
  }

  g#Nr path,
  g#NR path {
    pointer-events: none;
  }
`

export const image = css``

export const active = css`
  ${font({ size: 'medium' })}
  position: fixed;
  bottom: 0;
  padding-bottom: calc(var(--gap) / 2);
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  z-index: 999;
  justify-content: center;
  pointer-events: none;
`

export const activeBox = css`
  display: flex;
  background: rgba(255, 204, 0, 0.4);
  border: 2px solid #ffcc00;
  color: #f6a200;
  margin: 0 auto;
  height: 64px;
  border-radius: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.1);

  border-color: var(--colors-path-text);
  color: var(--color-path-text);
  background-color: var(--color-path-base);
`

export const activeTitle = css`
  ${font({ weight: 'bold' })}
  padding-bottom: 4px;
  margin-bottom: 4px;
`

export const activeMain = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const activeName = css`
  filter: brightness(0.9);
`

export const activeBooth = css`
  filter: brightness(0.9);
  ${font({ weight: 'bold', size: 'xsmall' })}
  margin-right: 12px;
  position: relative;
  top: 1px;
`
