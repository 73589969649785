import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { transition } from '~/base'
import { AnimateInView, HandThumbLeftIcon, HandThumbRightIcon, RichText, Title } from '~/components'
import { ExhibitorFeaturesBlockStoryblok } from '~/storyblok'

import * as styles from './FeaturesBlock.css'

type Props = {
  blok: ExhibitorFeaturesBlockStoryblok
}

const listVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { ...transition, staggerChildren: 0.5 },
  },
}

export const featureVariants = {
  hidden: {
    height: '0',
  },
  visible: {
    height: 'auto',
    // transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

export const featureIconVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    // transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

export const featureBoxVariants = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    // transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

export function FeaturesBlock({ blok }: Props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <div css={styles.wrapper}>
      <div css={styles.container}>
        <div css={styles.content}>
          <div css={styles.main}>
            <div css={styles.mainInner}>
              <AnimateInView>
                <Title label={blok.label} size="xlarge">
                  {blok.title}
                </Title>
              </AnimateInView>
              <AnimateInView>
                <RichText document={blok.text} />
              </AnimateInView>
            </div>
          </div>
          <div css={styles.features}>
            <motion.ul
              css={styles.list}
              ref={ref}
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={listVariants}
            >
              {blok.features?.map((feature, index) => {
                const isEven = (index + 1) % 2 === 0
                return (
                  <motion.li key={feature._uid} css={styles.item} variants={featureVariants}>
                    <div css={[styles.feature, isEven && styles.featureReverse]}>
                      <motion.div variants={featureIconVariants}>
                        {isEven ? (
                          <HandThumbLeftIcon css={styles.featureIconRight} />
                        ) : (
                          <HandThumbRightIcon css={styles.featureIconLeft} />
                        )}
                      </motion.div>
                      <motion.div css={styles.featureBox} variants={featureBoxVariants}>
                        {feature.text}
                      </motion.div>
                    </div>
                  </motion.li>
                )
              })}
            </motion.ul>
          </div>
        </div>
      </div>
    </div>
  )
}
