import { css } from '@emotion/react'

import { font, radius } from '~/base'

export const wrapper = css`
  display: block;
  text-decoration: none;
  ${radius('medium')}
  overflow: hidden;
  transform: translateZ(0); // fix Safari not applying overflow: hidden to nested image

  img {
    transition: transform 0.35s ease;
  }

  svg {
    transition: all 0.35s ease;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }

    svg {
      transform: scale(1.05);
      transform-origin: bottom;
    }
  }
`

export const box = css`
  position: relative;
  overflow: hidden;
`

export const image = css`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
`

export const main = css`
  color: #fff;
  padding: 20px 36px;
  background: var(--colors-primary);
  ${radius('medium')}
  position: relative;
  z-index: 1;
  margin-top: -36px;
`

export const category = css`
  ${font({ size: 'xsmall', weight: 'bold' })}
  margin-bottom: 3px;
  /*
  // TODO: Parent needs a fixed with first.
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  */
`

export const title = css`
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
`

export const background = css`
  display: block;
  z-index: 0;
`
