export function HandDownIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.52 80.49" {...props}>
      <g
        data-name="Gruppe 182"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Pfad 68"
          d="M28.715 11.948v8.191a4.207 4.207 0 001.381 3.179c2.412 2.928 7.155 9.158 7.4 13.081a9.358 9.358 0 01-4.323 8.85h0L32.3 76.067a3.152 3.152 0 01-3.489 3.424c-3.294 0-3.455-2.7-3.669-3.4-.032-.105-.919-21-.919-21s-.873 2.112-4.079 2.112-4.556-1.529-4.556-4.733v.774s.043 3.069-3.863 3.069a3.9 3.9 0 01-3.865-3.065 3.471 3.471 0 01-3.195 1.261c-2.142-.245-3.293-2.716-3.416-4.279s0-9.218 0-9.218-.721-13.194 5.35-18.939c1.331-1.257.658-9.991.658-9.991"
        />
        <path
          data-name="Pfad 69"
          d="M7.853 53.247l.07-12.417a3.6 3.6 0 013.795-2.66c4.221 0 3.863 3.74 3.863 3.74v10.27"
        />
        <path data-name="Pfad 70" d="M15.582 45.091v-4.247s.5-2.686 3.024-2.686c2.559 0 2.706.591 3.344 1.3" />
        <path
          data-name="Pfad 71"
          d="M28.715 20.139a4.207 4.207 0 001.381 3.179c2.412 2.928 7.155 9.158 7.4 13.081.327 5.267-3.073 9.552-7.465 9.77-2.81.14-5.631.111-8.444.081-1.71-.018-5.182 1.327-5.039-1.459a5.609 5.609 0 01.788-2.414c1.541-2.581 4.883-3.186 7.647-3.234a1.158 1.158 0 00.219-2.3c-1.912-.2-7.462-.451-7.462-6.1l.3-3.457"
        />
        <path data-name="Pfad 72" d="M1.243 41.011s-.721-13.194 5.35-18.939" />
        <path data-name="Linie 10" d="M24.216 55.091v-8.826" />
        <path data-name="Pfad 73" d="M1.242 41.011s.718-2.431 3.023-2.431c3.588 0 3.654 3.044 3.654 3.044" />
        <path
          data-name="Pfad 74"
          d="M29.476 1a1.437 1.437 0 011.436 1.435v7.881a1.433 1.433 0 01-1.435 1.436H6.183a1.434 1.434 0 01-1.435-1.435V2.433A1.433 1.433 0 016.183.999z"
        />
      </g>
    </svg>
  )
}
