import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { fadeVariants } from '~/base'
import { PlayIcon } from './PlayIcon'

import * as styles from './Video.css'

export function Video({ src, preview }: any) {
  const [ref, inView] = useInView({
    rootMargin: '-60px 0px -60px 0px',
  })
  const videoRef = useRef(null)
  const [videoPlaying, setVideoPlaying] = useState(false)

  function togglePause() {
    if (videoRef && videoRef.current) {
      const myVideo: any = videoRef.current
      if (myVideo && myVideo.paused) {
        myVideo.play()
        setVideoPlaying(true)
      } else {
        myVideo.pause()
        setVideoPlaying(false)
      }
    }
  }

  return (
    <motion.div
      css={styles.canvas}
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={fadeVariants}
    >
      <AnimatePresence>
        {!videoPlaying && (
          <motion.div css={styles.overlay} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <motion.button css={styles.play} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <PlayIcon css={styles.playIcon} />
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
      <video
        poster={preview || undefined}
        loop
        controls={videoPlaying}
        autoPlay={videoPlaying}
        playsInline
        ref={videoRef}
        onClick={!videoPlaying ? togglePause : undefined}
      >
        <source src={src} type="video/mp4" />
      </video>
    </motion.div>
  )
}
