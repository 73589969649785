import { motion } from 'framer-motion'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { CTA, Checkbox, HandRightIcon, Title } from '~/components'
import { useExhibitor } from '~/features/exhibitors/Exhibitor'
import { ExhibitorContactBlockStoryblok } from '~/storyblok'
import { resizeImage } from '~/storyblok/resizeImage'

import * as styles from './ContactBlock.css'

type Props = {
  blok: ExhibitorContactBlockStoryblok
}

type SendStatus = 'SENDING' | 'SENT'

const initialData = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  message: '',
}

const wrapperVariants = {
  hidden: {
    x: '-100%',
  },
  visible: {
    x: '0',
    transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { type: 'spring', stiffness: 600, damping: 100, delay: 0.25 },
  },
}

export function ContactBlock({ blok }: Props) {
  const exhibitor = useExhibitor()
  const [data, setData] = useState({ ...initialData })
  const [status, setStatus] = useState<SendStatus | undefined>(undefined)

  const [ref, inView] = useInView({
    rootMargin: '-80px 0px -80px 0px',
  })

  function reset() {
    setData({ ...initialData })
  }

  function onRetry() {
    setStatus(undefined)
  }

  function onChange(key, value) {
    setData({
      ...data,
      [key]: value,
    })
  }

  function onSubmit(e) {
    e.preventDefault()
    setStatus('SENDING')

    const postData = {
      ...data,
      slug: exhibitor!.slug,
    }

    fetch('/api/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then(() => {
        setStatus('SENT')
        reset()
      })
  }

  const resizedPhoto = resizeImage(blok.photo, { width: 488, height: 488 })

  return (
    <motion.div
      css={styles.wrapper}
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={wrapperVariants}
    >
      <div css={styles.container}>
        <Title color={false} size="xlarge" label="Kontakt" spacing="large">
          Bereit für deine Fragen.
        </Title>
        <motion.div css={styles.content} variants={contentVariants}>
          <div css={styles.main}>
            <form css={styles.form} onSubmit={onSubmit}>
              {status === 'SENT' && (
                <div css={styles.status}>
                  <strong>Vielen Dank für deine Nachricht.</strong>
                  <br />
                  Wir melden uns so bald wie möglich zurück.
                  <br />
                  <button css={styles.statusRetry} onClick={onRetry}>
                    Noch eine Frage senden
                  </button>
                </div>
              )}
              {status === 'SENDING' && (
                <div css={styles.status}>
                  <strong>⌛ Deine Frage wird gesendet...</strong>
                </div>
              )}
              {!status && (
                <>
                  <div css={styles.formItems}>
                    <div css={styles.formItem}>
                      <input
                        type="text"
                        placeholder="Vorname*"
                        required
                        css={styles.formInput}
                        onChange={(e) => onChange('firstname', e.target.value)}
                      />
                    </div>
                    <div css={styles.formItem}>
                      <input
                        type="text"
                        placeholder="Nachname*"
                        required
                        css={styles.formInput}
                        onChange={(e) => onChange('lastname', e.target.value)}
                      />
                    </div>
                  </div>
                  <div css={styles.formItems}>
                    <div css={styles.formItem}>
                      <input
                        type="text"
                        placeholder="E-Mail*"
                        required
                        css={styles.formInput}
                        onChange={(e) => onChange('email', e.target.value)}
                      />
                    </div>
                    <div css={styles.formItem}>
                      <input
                        type="text"
                        placeholder="Telefon"
                        css={styles.formInput}
                        onChange={(e) => onChange('phone', e.target.value)}
                      />
                    </div>
                  </div>
                  <div css={styles.formItems}>
                    <div css={styles.formItem}>
                      <textarea
                        placeholder="Deine Nachricht"
                        css={styles.formTextarea}
                        onChange={(e) => onChange('message', e.target.value)}
                      ></textarea>
                    </div>
                    <div css={[styles.formItem, styles.formItemEnd]}>
                      <div css={styles.formMeta}>
                        <Checkbox name="agb" required>
                          Ich akzeptieren die Datenschutz&shy;bestimmungen
                        </Checkbox>
                      </div>
                      <div css={styles.formAction}>
                        <CTA icon={<HandRightIcon css={styles.formButtonIcon} />} css={styles.formButton} type="submit">
                          JETZT ANFRAGEN
                        </CTA>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
          <div css={styles.meta}>
            <div css={styles.person}>
              <div css={styles.personPhoto}>
                <img src={resizedPhoto.url} alt="" css={styles.personPhotoImage} />
              </div>
              <div css={styles.personMain}>
                <h2 css={styles.personTitle}>{blok.name}</h2>
                {blok.phone}
                <br />
                <a href={`mailto:${blok.email}`}>{blok.email}</a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  )
}
