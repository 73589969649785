import { css } from '@emotion/react'
import { font, FontSize, FontWeight } from '~/base'

type Options = {
  size?: FontSize
  weight?: FontWeight
}

export function text({ weight, size }: Options = {}) {
  return css`
    white-space: pre-line;
    ${font({ weight, size })}
  `
}
