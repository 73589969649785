export const locations = {
  'Grosser Saal': {
    name: 'Grosser Saal',
    colorBase: '#FDE0A3',
    colorText: '#F6A201',
  },
  Foyer: {
    name: 'Foyer',
    colorBase: '#CEE1C0',
    colorText: '#86B360',
  },
  'Kleiner Saal': {
    name: 'Kleiner Saal',
    colorBase: '#ACC0DE',
    colorText: '#3363AC',
  },
  Lindaplatz: {
    name: 'Lindaplatz',
    colorBase: '#FACDCF',
    colorText: '#D41417',
  },
}
