import { getStoryblokApi } from '@storyblok/react'
import { ExhibitorStory } from './types'

export async function fetchExhibitors(preview?: boolean): Promise<ExhibitorStory[]> {
  const storyblok = getStoryblokApi()

  const { data } = await storyblok.get(`cdn/stories`, {
    version: preview ? 'draft' : 'published',
    resolve_links: 'story',
    resolve_relations: ['ExhibitorsBlock.exhibitors'],
    starts_with: 'aussteller/',
    is_startpage: false,
    per_page: 100,
    // cv: Date.now(),
  })

  return data.stories
}
