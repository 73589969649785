import { AssetStoryblok } from './generated'
import { Image } from './types'

export type ResizeOptions = {
  width?: number
  height?: number
  smart?: boolean
}

const ASSET_CDN = 'https://img2.storyblok.com'

function getAssetURLParts(assetURL: string) {
  const pathname = assetURL.replace('https://a.storyblok.com/', '')
  const [prefix, spaceID, size, uniqueID, filename] = pathname.split('/')

  return {
    prefix,
    spaceID,
    size,
    uniqueID,
    filename,
    pathname,
  }
}

function calculateWidth(originalWidth: number, originalHeight: number, newHeight: number) {
  return Math.round((originalWidth / originalHeight) * newHeight)
}

function calculateHeight(originalWidth: number, originalHeight: number, newWidth: number): number {
  return Math.round((originalHeight / originalWidth) * newWidth)
}

export function resizeImage(image: AssetStoryblok, options: ResizeOptions): Image {
  const parts = getAssetURLParts(image.filename)
  const smart = options.smart ? '/smart' : ''
  const url = `${ASSET_CDN}/${options.width}x${options.height}${smart}/${parts.pathname}`

  // Calculate the new image sized and respect proportional resizing.
  const size = parts.size.split('x')
  const originalWidth = parseInt(size[0])
  const originalHeight = parseInt(size[1])
  const width =
    options.width && options.width !== 0
      ? options.width
      : calculateWidth(originalWidth, originalHeight, options.height || 0)
  const height =
    options.height && options.height !== 0
      ? options.height
      : calculateHeight(originalWidth, originalHeight, options.width || 0)

  return {
    id: image.id,
    url,
    height,
    width,
    alt: image.alt,
    name: image.name,
    title: image.title,
    copyright: image.copyright,
    filename: url,
    orientation: width > height ? 'landscape' : height > width ? 'portrait' : 'square',
    mime: image.mime,
  }
}
