import * as styles from './Checkbox.css'

export function Checkbox({ children, name, css, className, ...props }: any) {
  return (
    <div css={styles.container}>
      <input type="checkbox" id={name} name={name} css={[styles.checkbox, css]} {...props} />
      <label css={styles.label} htmlFor={name}>
        {children}
      </label>
    </div>
  )
}
