import { css } from '@emotion/react'

import { breakpoints } from './breakpoints'

export type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge'

export const sizeVariants = {
  xsmall: css`
    --border-radius: 10px;
  `,
  small: css`
    --border-radius: 20px;
  `,
  medium: css`
    --border-radius: 40px;
  `,
  large: css`
    --border-radius: 50px;
  `,
  xlarge: css`
    --border-radius: 60px;
  `,
  xxlarge: css`
    --border-radius: 60px;

    @media ${breakpoints.large} {
      --border-radius: 200px;
    }
  `,
}

/** Usage: radius('large', true) */
export function radius(size: Size, topRightOnly?: boolean) {
  return css`
    ${sizeVariants[size]}
    border-top-right-radius: var(--border-radius);

    ${!topRightOnly &&
    css`
      border-bottom-left-radius: var(--border-radius);
    `}
  `
}
