export function HandLeftIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.6 30.946" {...props}>
      <g
        data-name="Gruppe 175"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Pfad 59"
          d="M50.492 24.971c-.745 0-2.1-.008-3.044-.008a3.383 3.383 0 00-2.361.785c-3.022 2.685-7.228 4.2-9.6 4.2H24.549s-3.043-.174-3.043-2.8a2.3 2.3 0 011.481-2.3s-3.677-.5-3.677-3.429a3.446 3.446 0 011.829-3.19s-3.228-.826-3.372-3.166a3.992 3.992 0 011.481-3.332H3.456A2.529 2.529 0 011 8.931a2.825 2.825 0 012.375-2.87l18.584-.9h.081L25.331 5s.163-2.467 3.451-3.619 10.856 1.562 12.581 2.3a12.975 12.975 0 013.541 3.139c.448.519.881.924 2.514.924 1.106 0 2.37.022 3.074.038"
        />
        <path data-name="Linie 9" d="M21.958 5.156l10.134-.42" />
        <path
          data-name="Pfad 60"
          d="M57.604 25.396a.931.931 0 01-.93.931h-5.247a.929.929 0 01-.931-.931V7.559a.931.931 0 01.93-.931h5.248a.93.93 0 01.93.931z"
        />
      </g>
    </svg>
  )
}
