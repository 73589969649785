import { CTA, Container, HandRightIcon, Link, Title } from '~/components'
import { ExhibitorsBlockStoryblok } from '~/storyblok'
import { resizeImage } from '~/storyblok/resizeImage'
import { ExhibitorStory } from '../types'

import * as styles from './ExhibitorsBlock.css'

type Props = {
  blok: ExhibitorsBlockStoryblok
}

export function ExhibitorsBlock({ blok }: Props) {
  return (
    <div css={styles.wrapper}>
      <Container>
        {blok.exhibitors?.map((exh, index) => {
          const exhibitor = exh as ExhibitorStory

          return (
            <div css={styles.exhibitor} key={index}>
              <div css={[styles.content, index % 2 !== 0 && styles.contentReverse]}>
                <div css={styles.media}>
                  <Link href={`/${exhibitor.full_slug}`}>
                    <img
                      src={resizeImage(exhibitor.content.image, { width: 798, height: 532 }).url}
                      alt=""
                      css={styles.image}
                    />{' '}
                  </Link>
                </div>
                <div css={styles.main}>
                  <Title label="Mitglied" size="xlarge">
                    {exhibitor.name}
                  </Title>
                  <p>{exhibitor.content.title}</p>
                  <div css={styles.logo}>
                    <Link href={`/${exhibitor.full_slug}`}>
                      <img src={exhibitor.content.logo.filename} alt="" css={styles.logoImage} />
                    </Link>
                  </div>
                  <div css={styles.cta}>
                    <CTA href={`/${exhibitor.full_slug}`} icon={<HandRightIcon css={styles.ctaIcon} />}>
                      zum Mitglied
                    </CTA>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </Container>
    </div>
  )
}
