export const breakpoints = {
  /** (min-width: 375px) */
  xxsmall: `(min-width: 375px)`,

  /** (min-width: 480px) */
  xsmall: `(min-width: 480px)`,

  /** (min-width: 640px) */
  small: `(min-width: 640px)`,

  /** (min-width: 768px) */
  medium: `(min-width: 768px)`,

  /** (min-width: 1024px) */
  large: `(min-width: 1024px)`,

  /** (min-width: 1280px)  */
  xlarge: `(min-width: 1280px)`,

  /** (min-width: 1536px) */
  xxlarge: `(min-width: 1536px)`,
}
