import Image from 'next/image'
import { Link } from '~/components'
import { resizeImage } from '~/storyblok/resizeImage'
import { ExhibitorStory } from './types'

import * as styles from './ExhibitorPreview.css'

type Props = {
  exhibitor: ExhibitorStory
}

export function ExhibitorPreview({ exhibitor }: Props) {
  const resizedImage = resizeImage(exhibitor.content.image, { width: 750, height: 750 })

  return (
    <Link href={`/aussteller/${exhibitor.slug}`} css={styles.wrapper}>
      <div css={styles.box}>
        <Image
          src={resizedImage.url}
          alt={exhibitor.name}
          css={styles.image}
          width={resizedImage.width}
          height={resizedImage.height}
        />
        <div css={styles.main}>
          <div css={styles.category}>{exhibitor.content.categories.join(', ')}</div>
          <h2 css={styles.title}>{exhibitor.name}</h2>
        </div>
      </div>
    </Link>
  )
}
