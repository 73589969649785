import { css } from '@emotion/react'

export const wrapper = css`
  position: relative;

  @media (min-width: 1024px) {
    margin-bottom: -100px;
    margin-top: -100px;
  }
`

export const box = css`
  background: var(--colors-primary);
  color: #fff;
  display: inline-block;
  padding: 1rem 1.5rem 0.5rem var(--gap);
  border-top-right-radius: 30px;
`

export const button = css`
  color: var(--colors-primary);
  display: block;
  border: 1px solid green;
  padding: 8px;
`

export const icon = css`
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
  top: -3px;
`
