import { css } from '@emotion/react'
import { breakpoints, font, radius } from '~/base'

export const wrapper = css`
  margin-bottom: 84px;
`

/** Intro */

export const intro = css`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  @media ${breakpoints.xlarge} {
    margin-bottom: -80px;
  }
`

export const introContent = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px;
`

export const introMedia = css`
  flex: auto;
  padding: 0 24px;
  margin-bottom: var(--container-padding);
  max-width: 580px;
  display: flex;
  align-items: center;

  @media ${breakpoints.small} {
    max-width: 100%;
    width: 80%;
    flex: none;
  }

  @media ${breakpoints.large} {
    flex: 1;
    margin-bottom: 0;
  }
`

export const media = css`
  padding-right: var(--container-padding);

  @media ${breakpoints.xxlarge} {
    padding-right: 20%;
  }
`

export const image = css`
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
  ${radius('xlarge', true)}

  @media ${breakpoints.large} {
    ${radius('xlarge')}
  }
`

export const introMain = css`
  flex: 1;
  padding: 0 24px;
  margin-bottom: 24px;

  @media ${breakpoints.large} {
    padding-top: 168px;
    padding: 168px 48px 128px 24px;
    margin-bottom: 0;
  }
`

export const introMainInner = css`
  max-width: 648px;
  padding: 0 var(--container-padding);

  @media ${breakpoints.large} {
    padding: 0;
  }
`

export const category = css`
  margin-bottom: 18px;
`

export const title = css`
  ${font({ size: 'xlarge' })}
  margin-bottom: 40px;
`

export const text = css``

export const main = css`
  margin-bottom: 110px;
  max-width: 782px;
  margin-top: -200px;

  padding: 0 48px 0 0;
  background: #fff;
  position: relative;
  z-index: 1;
`

/** More */

export const more = css`
  margin-bottom: 100px;
  text-align: center;
`

export const moreLabel = css`
  margin-bottom: 10px;
`

export const moreIcon = css`
  height: 78px;
  width: auto;
  color: var(--colors-primary);
  margin-bottom: 10px;
`

/** Plan */

export const plan = css`
  margin-bottom: 100px;
`

/** Footer */

export const share = css``

export const back = css`
  text-align: center;
`

export const backIcon = css`
  margin-right: 4px;
  height: 16px;
  position: relative;
  top: 2px;
`
