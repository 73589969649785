import { css } from '@emotion/react'
import { breakpoints } from '~/base'

type Options = {
  spacing?: 'medium' | 'large' | 'xlarge'
}

const spacingVariants = {
  medium: css`
    margin-bottom: 28px;
  `,
  large: css`
    margin-bottom: 68px;
  `,
  xlarge: css`
    margin-bottom: 48px;

    @media ${breakpoints.large} {
      margin-bottom: 94px;
    }
  `,
}

export function title({ spacing }: Options) {
  return css`
    ${spacing && spacingVariants[spacing]}
  `
}

export const label = css`
  margin-bottom: 10px;
`
