import { PlanDesktop } from './PlanDesktop'
import { PlanMobile } from './PlanMobile'

import * as styles from './Plan.css'

export function Plan() {
  return (
    <>
      <div css={styles.desktop}>
        <PlanDesktop />
      </div>
      <div css={styles.mobile}>
        <PlanMobile />
      </div>
    </>
  )
}
