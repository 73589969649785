export const transition = { type: 'spring', stiffness: 600, damping: 100 }

export const fadeVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

export const slideVariants = {
  hidden: {
    left: '-100%',
  },
  visible: {
    left: '0%',
    transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}
