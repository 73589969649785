import { css } from '@emotion/react'

export const wrapper = css``

export const exhibitor = css`
  margin-bottom: 100px;

  @media (min-width: 1024px) {
    margin-bottom: 160px;
  }
`

export const content = css`
  display: flex;
  flex-wrap: wrap;
  margin: -24px;
  align-items: center;
`

export const contentReverse = css`
  flex-direction: row-reverse;
`

const sharedItems = css`
  flex: 1;
  padding: 24px;
  min-width: 280px;
`

export const media = css`
  ${sharedItems}
`

export const main = css`
  ${sharedItems}
`

export const image = css`
  display: block;
  max-width: 100%;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
`

export const cta = css`
  color: var(--colors-primary);
  margin-top: 40px;
`

export const ctaIcon = css`
  height: 36px;
`

export const logo = css`
  width: 220px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const logoImage = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`
