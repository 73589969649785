import { css } from '@emotion/react'
import { breakpoints, font, radius } from '~/base'
import { containerStyle } from '~/components/Container/Container.css' // TODO: Why?

export const wrapper = css`
  margin-bottom: 100px;
`

export const container = css`
  ${containerStyle({ width: 'large' })}
  ${radius('xxlarge')}
  background: #f5f4f4;
  padding: 48px var(--container-padding);

  @media ${breakpoints.large} {
    padding: 96px;
  }

  @media ${breakpoints.xlarge} {
    padding: 144px;
  }
`

export const content = css`
  display: flex;
  flex-wrap: wrap;
  margin: -24px;
  align-items: center;
`

const sharedItems = css`
  flex: 1;
  padding: 24px;
  min-width: 280px;
`

export const features = css`
  ${sharedItems}
  display: flex;
  /*
  align-self: flex-end;
  align-items: flex-end;
  */
`

export const main = css`
  ${sharedItems}
`

export const mainInner = css`
  width: 524px;
  max-width: 100%;
`

/** Features */

export const list = css`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const item = css`
  padding-top: 48px;
  overflow: hidden;

  &:first-of-type {
    padding-top: 0;
  }
`

export const feature = css`
  display: flex;
  align-items: center;
  ${font({ size: 'small' })}// justify-content: center;
`

export const featureReverse = css`
  flex-direction: row-reverse;
`

export const featureMedia = css``

const featureIconBase = css`
  height: 48px;
  color: var(--colors-primary);
  display: block;
  flex: none;

  @media ${breakpoints.large} {
    height: 78px;
  }
`

export const featureIconLeft = css`
  ${featureIconBase}
  margin-right: 24px;

  @media ${breakpoints.large} {
    margin-left: 24px;
  }
`

export const featureIconRight = css`
  ${featureIconBase}
  margin-left: 24px;

  @media ${breakpoints.large} {
    margin-right: 24px;
  }
`

export const featureBox = css`
  ${font({ weight: 'bold' })}
  background: var(--colors-primary);
  color: #fff;
  padding: 24px;
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  transform-origin: left bottom;

  // flex: 1;
`
