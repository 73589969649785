import React, { createContext } from 'react'
import { ExhibitorStory } from './types'

type Props = {
  exhibitors?: ExhibitorStory[]
  children: React.ReactNode
}

// type ExhibitorContextType = {
//   exhibitors: Exhibitor[]
//   setExhibitors: (exhibitors: Exhibitor[]) => void
// }

export const ExhibitorsContext = createContext<ExhibitorStory[] | undefined>(undefined)

export function ExhibitorsProvider({ exhibitors, children }: Props) {
  return <ExhibitorsContext.Provider value={exhibitors}>{children}</ExhibitorsContext.Provider>
}
