export function formatURL(url: string) {
  url = url.replace('https://', '')
  url = url.replace('http://', '')

  if (url.slice(-1) === '/') {
    url = url.substring(0, url.length - 1)
  }

  return url
}
