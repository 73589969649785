import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

type Props = {
  delay?: number
  children: React.ReactNode
  visible?: object
  hidden?: object
}

export function AnimateInView({ delay = 0, visible, hidden, children }: Props) {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    rootMargin: '-80px 0px -80px 0px',
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  const visibleVariant = visible || { opacity: 1, y: 0 }
  const hiddenVariant = hidden || { opacity: 0, y: 24 }

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ delay: delay / 1000 }}
      variants={{
        visible: visibleVariant,
        hidden: hiddenVariant,
      }}
    >
      {children}
    </motion.div>
  )
}
