import { css } from '@emotion/react'
import { breakpoints, font } from '~/base'

export const item = css`
  padding-bottom: 2rem;
  position: relative;

  @media ${breakpoints.small} {
    padding-bottom: 1rem;
  }

  // right side
  &:nth-child(odd) {
    text-align: left;
    padding-left: 54px;
    margin-left: 6px;

    @media ${breakpoints.small} {
      margin-left: 50%;
      padding-left: 80px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
      width: 2px;
      height: 100%;
      background-color: var(--colors-primary);
    }
  }

  // left side
  &:nth-child(even) {
    margin-left: 6px;
    padding-left: 54px;

    @media ${breakpoints.small} {
      margin-left: 0;
      padding-left: 0;
      padding-right: 80px;
      margin-right: 50%;
      text-align: right;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
      width: 2px;
      height: 100%;
      background-color: var(--colors-primary);

      @media ${breakpoints.small} {
        left: auto;
        right: -1px;
      }
    }
  }

  &:first-of-type {
    padding-top: 1.5rem;
  }

  &:last-of-type {
    padding-bottom: 1.5rem;
  }
`

export const main = css`
  position: relative;
`

export const indicator = css`
  // line
  display: block;
  position: absolute;
  top: 2.1rem;
  background-color: var(--colors-primary);
  width: 36px;
  height: 2px;

  @media ${breakpoints.small} {
    width: 64px;
  }

  // dot
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    width: 18px;
    height: 18px;
    border: 2px solid var(--colors-primary);
    border-radius: 18px;
    background: #fff;
    transform: translateY(-50%);
  }
`

// right side
export const indicatorOdd = css`
  left: -54px;

  &::before {
    left: 26px;
  }

  @media ${breakpoints.small} {
    left: -80px;

    &::before {
      left: 48px;
    }
  }
`

// left side
export const indicatorEven = css`
  left: -54px;

  &::before {
    left: 26px;
  }

  @media ${breakpoints.small} {
    left: auto;
    right: -80px;

    &::before {
      left: auto;
      right: 48px;
    }
  }
`

export const time = css`
  color: var(--colors-primary);
`

export const title = css`
  ${font({ weight: 'bold' })}
  color: var(--colors-primary);
`

export const text = css`
  ${font({ size: 'small' })}
  margin-top: 0.75rem;
`
