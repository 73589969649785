export function HandThumbLeftIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77.314 79.418" {...props}>
      <g data-name="Gruppe 173" fill="none" stroke="currentColor" strokeWidth={3}>
        <path
          d="M50.183 77.831s-19.034 1.04-27.324-7.719c-1.813-1.92-9.7-.948-9.7-.948l-.19-30.963s5.906.434 8.452-1.247c2.85-1.88 21.308-19.405 22.717-22.364s2.537-10.107 2.642-11.9 8.346-2.747 9.086 6.444c.369 4.582-1.556 9.045-3.626 13.02-1.172 2.251-5.567 7.183-2.962 9.693 1.935 1.865 4.354 1.051 8.912 1.051h13.127a5.6 5.6 0 014.5 5.734 6.394 6.394 0 01-4.739 6.147h-3.614v.007s6.085-.455 6.085 5.785c0 4.624-2.206 6.575-6.83 6.575h1.117s4.428-.064 4.428 5.574a5.632 5.632 0 01-4.423 5.576 5.01 5.01 0 011.82 4.609c-.354 3.09-3.919 4.751-6.174 4.929s-13.304-.003-13.304-.003z"
          strokeLinejoin="round"
        />
        <path d="M56.071 57.143l-3.59-.011s-5.56-.43-5.56-6.218 5.668-6.13 5.668-6.13h14.872" strokeMiterlimit={10} />
        <path
          d="M57.35 44.781l-3.408-.011a5.911 5.911 0 01-5.278-6.028c0-5.5 5.636-5.708 5.636-5.708"
          strokeMiterlimit={10}
        />
        <path
          d="M67.835 68.292l-17.914-.1s-3.838-.764-3.838-5.474c0-6.09 5.4-5.574 5.4-5.574h15.234"
          strokeMiterlimit={10}
        />
        <path
          d="M1.497 69.726a1.7 1.7 0 001.7 1.7h8.075a1.7 1.7 0 001.7-1.7V37.219a1.7 1.7 0 00-1.7-1.7H3.197a1.7 1.7 0 00-1.695 1.7z"
          strokeLinejoin="round"
        />
        <path d="M50.183 77.831s-3.506-1.036-3.506-4.362c0-5.176 4.392-5.271 4.392-5.271" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
