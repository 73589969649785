export function HandLeftAltIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.49 38.52" {...props}>
      <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <path d="M68.542 9.806h-8.191a4.207 4.207 0 01-3.179-1.381c-2.928-2.412-9.158-7.155-13.081-7.4a9.358 9.358 0 00-8.85 4.323h0l-30.818.873A3.152 3.152 0 00.999 9.71c0 3.294 2.7 3.455 3.4 3.669.105.032 21 .919 21 .919s-2.112.873-2.112 4.079 1.529 4.556 4.733 4.556h-.774s-3.069-.043-3.069 3.863a3.9 3.9 0 003.065 3.865 3.471 3.471 0 00-1.261 3.195c.245 2.142 2.716 3.293 4.279 3.416s9.218 0 9.218 0 13.194.721 18.939-5.35c1.257-1.331 9.991-.658 9.991-.658" />
        <path d="M27.243 30.668l12.417-.07a3.6 3.6 0 002.66-3.795c0-4.221-3.74-3.863-3.74-3.863H28.312" />
        <path d="M35.399 22.939h4.247s2.686-.5 2.686-3.024c0-2.559-.591-2.706-1.3-3.344" />
        <path d="M60.351 9.806a4.207 4.207 0 01-3.179-1.381c-2.928-2.412-9.158-7.155-13.081-7.4-5.267-.327-9.552 3.073-9.77 7.465-.14 2.81-.111 5.631-.081 8.444.018 1.71-1.327 5.182 1.459 5.039a5.609 5.609 0 002.414-.788c2.581-1.541 3.186-4.883 3.234-7.647a1.158 1.158 0 012.3-.219c.2 1.912.451 7.462 6.1 7.462l3.457-.3" />
        <path d="M39.48 37.278s13.194.721 18.939-5.35" />
        <path d="M25.399 14.305h8.826" />
        <path d="M39.48 37.279s2.431-.718 2.431-3.023c0-3.588-3.044-3.654-3.044-3.654" />
        <path d="M79.49 9.045a1.437 1.437 0 00-1.435-1.436h-7.881a1.433 1.433 0 00-1.436 1.435v23.294a1.434 1.434 0 001.435 1.435h7.884a1.433 1.433 0 001.434-1.435z" />
      </g>
    </svg>
  )
}
