import { Container, Title } from '~/components'
import { ExhibitorVideoBlockStoryblok } from '~/storyblok'

import dynamic from 'next/dynamic'
import * as styles from './VideoBlock.css'

type Props = {
  blok: ExhibitorVideoBlockStoryblok
}

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

export function VideoBlock({ blok }: Props) {
  return (
    <div css={styles.wrapper}>
      <Container>
        <div css={styles.heading}>
          {(blok.label || blok.title) && (
            <Title label={blok.label} size="xlarge">
              {blok.title}
            </Title>
          )}
        </div>
        <div css={styles.canvas}>
          <ReactPlayer url={blok.url} width="100%" height="100%" className="player" controls />
        </div>
      </Container>
    </div>
  )
}
