import { motion } from 'framer-motion'
import { shuffle } from 'lodash'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { CTA, HandDownIcon } from '~/components'
import { ExhibitorPreview } from './ExhibitorPreview'
import { ExhibitorStory } from './types'
import { useExhibitors } from './useExhibitors'

import * as styles from './Exhibitors.css'

const itemsMotion = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
}

const itemMotion = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

export function Exhibitors() {
  const [randomExhibitors, setRandomExhibitors] = useState<ExhibitorStory[]>([])
  const [showAll, setShowAll] = useState(false)

  const exhibitors = useExhibitors()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  })

  useEffect(() => {
    const randomExhibitors = shuffle(exhibitors)

    setRandomExhibitors(randomExhibitors)
  }, [])

  function onShowMore() {
    setShowAll(true)
  }

  const shownExhibitors = showAll ? randomExhibitors : randomExhibitors.slice(0, 12)

  return (
    <div ref={ref}>
      <motion.ul css={styles.items} variants={itemsMotion} initial="hidden" animate={inView ? 'show' : 'hidden'}>
        {shownExhibitors?.map((exhibitor) => (
          <motion.li css={styles.item} key={exhibitor.id} variants={itemMotion}>
            <ExhibitorPreview exhibitor={exhibitor} />
          </motion.li>
        ))}
      </motion.ul>
      {!showAll && (
        <div css={styles.more}>
          <CTA onClick={onShowMore} icon={<HandDownIcon css={styles.moreIcon} />} iconPosition="top">
            Mehr entdecken
          </CTA>
        </div>
      )}
    </div>
  )
}
