import { css } from '@emotion/react'
import { font, FontSize, FontWeight } from '~/base'

type Options = {
  size?: FontSize
  weight?: FontWeight
}

export function heading({ weight, size }: Options = {}) {
  return css`
    margin: 0;
    ${font({ weight, size })}
    line-height: 1;

    /* try hypens, but at least break word */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  `
}
