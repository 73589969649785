import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { transition } from '~/base'
import { ExhibitorTimelineBlockItemStoryblok } from '~/storyblok'

import * as styles from './ExhibitorTimelineBlockItem.css'

type Props = React.ComponentPropsWithoutRef<'div'> & {
  blok: ExhibitorTimelineBlockItemStoryblok
  index: number
}

const mainVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { ...transition },
  },
}

const contentVariants = {
  hidden: {
    y: 40,
  },
  visible: {
    y: 0,
    transition: { ...transition },
  },
}

export function ExhibitorTimelineBlockItem({ blok, index }: Props) {
  const { ref, inView } = useInView({
    rootMargin: '100px 0px -180px 0px',
    delay: 200,
  })

  return (
    <motion.div css={styles.item} key={blok.id} ref={ref} initial="hidden" animate={inView ? 'visible' : 'hidden'}>
      <motion.div css={styles.main} variants={mainVariants}>
        <span css={[styles.indicator, index % 2 ? styles.indicatorEven : styles.indicatorOdd]} />
        <motion.div variants={contentVariants}>
          <div css={styles.time}>{blok.time}</div>
          <div css={styles.title}>
            <strong>{blok.title}</strong>
          </div>
          {blok.text && <div css={styles.text}>{blok.text}</div>}
        </motion.div>
      </motion.div>
    </motion.div>
  )
}
