import React, { createContext } from 'react'
import { ExhibitorStory } from '~/features/exhibitors'

type Props = {
  exhibitor?: ExhibitorStory
  children: React.ReactNode
}

export const ExhibitorContext = createContext<ExhibitorStory | undefined>(undefined)

export function ExhibitorProvider({ exhibitor, children }: Props) {
  return <ExhibitorContext.Provider value={exhibitor}>{children}</ExhibitorContext.Provider>
}
