import { css } from '@emotion/react'
import { breakpoints } from './breakpoints'

import { font } from './font'

export const global = css`
  :root {
    --colors-base: #101010;
    --colors-primary: #d2271c;
    --colors-meta: #f5f4f4;

    --gap: 24px;

    --container-padding: var(--gap);
    --container-width: 1410px; // 1362px
  }

  @media ${breakpoints.small} {
    :root {
      --gap: 48px;
    }
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
    color: #101010;
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    /* overflow-x: hidden; */

    ${font({ size: 'medium' })}
  }

  html,
  body,
  #__next {
    height: 100%;
  }

  a {
    color: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  b {
    ${font({ weight: 'bold' })}
  }

  p {
    margin-top: 0;
  }
`
