import { getStoryblokApi } from '@storyblok/react'
import { ExhibitorStory } from './types'

export async function fetchExhibitor(slug: string, preview?: boolean): Promise<ExhibitorStory> {
  const storyblok = getStoryblokApi()

  const { data } = await storyblok.get(`cdn/stories/aussteller/${slug}`, {
    version: preview ? 'draft' : 'published',
    resolve_links: 'story',
    resolve_relations: ['ExhibitorsBlock.exhibitors'],
    cv: Date.now(),
  })

  return data.story
}
