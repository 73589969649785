import React, { createContext, useContext } from 'react'
import { ExhibitorCategory } from './types'

// TODO: Rename to useExhibitorCategories to be more explicit?

type Props = {
  categories?: ExhibitorCategory[]
  children: React.ReactNode
}

export const CategoriesContext = createContext<ExhibitorCategory[] | undefined>(undefined)

export function CategoriesProvider({ categories, children }: Props) {
  return <CategoriesContext.Provider value={categories}>{children}</CategoriesContext.Provider>
}

export function useCategories() {
  return useContext(CategoriesContext)
}
