import Image from 'next/image'
import { CTA, Container, HandRightIcon, RichText, Title } from '~/components'
import { ExhibitorContentBlockStoryblok } from '~/storyblok'
import { resizeImage } from '~/storyblok/resizeImage'

import * as styles from './ContentBlock.css'

type Props = {
  blok: ExhibitorContentBlockStoryblok
}

export function ContentBlock({ blok }: Props) {
  const reverse = blok.visual_order === 'main_media'
  const hasCTA = blok.cta_text && blok.cta_url
  const resizedImage = resizeImage(blok.image, { width: 798, height: 532 })

  return (
    <div css={styles.wrapper}>
      <Container>
        <div css={[styles.content, reverse && styles.contentReverse]}>
          <div css={styles.media}>
            <Image
              src={resizedImage.url}
              alt={resizedImage.alt || ''}
              css={styles.image}
              width={resizedImage.width}
              height={resizedImage.height}
            />
          </div>
          <div css={styles.main}>
            <Title label={blok.label} size="xlarge">
              {blok.title}
            </Title>
            <RichText document={blok.text} />
            {hasCTA && (
              <div css={styles.cta}>
                <CTA
                  href={blok.cta_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<HandRightIcon css={styles.ctaIcon} />}
                >
                  {blok.cta_text}
                </CTA>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}
