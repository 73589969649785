import { css } from '@emotion/react'

export const container = css`
  position: relative;
  overflow: hidden;
`

export const checkbox = css`
  position: absolute;
  left: 4px;
  top: 6px;
  opacity: 0;

  &:checked ~ label {
    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 8px;
      left: 3px;
      background: #fff;
    }
  }
`

export const label = css`
  display: block;
  padding-left: 28px;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    position: absolute;
    top: 5px;
    left: 0;
  }
`
