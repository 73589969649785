import { css } from '@emotion/react'
import { breakpoints } from '~/base'

export const items = css`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -24px;
  list-style: none;
`

export const item = css`
  padding: 24px;

  @media ${breakpoints.small} {
    flex-basis: 50%;
    width: 50%;

    &:nth-of-type(2n) {
      margin-top: 60px;
      margin-bottom: -60px;
    }
  }

  @media ${breakpoints.large} {
    flex-basis: 33.333333%;
    width: 33.333333%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    &:nth-of-type(3n + 2) {
      margin-top: 98px !important;
      margin-bottom: -98px !important;
    }
  }
`

export const more = css`
  display: flex;
  justify-content: center;
  color: var(--colors-primary);
  padding-top: 76px;

  @media ${breakpoints.large} {
    margin-top: 112px;
  }
`

export const moreIcon = css`
  height: 80px;
`
