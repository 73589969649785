import { css } from '@emotion/react'
import { breakpoints } from '~/base'

export const canvas = css`
  position: relative;
  border-top-right-radius: 40px;
  overflow: hidden;
  border-bottom-left-radius: 40px;

  video {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
    cursor: pointer;
  }
`

export const overlay = css`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
`

export const play = css`
  display: block;
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
  pointer-events: none;

  @media ${breakpoints.large} {
    width: 130px;
    height: 130px;
  }

  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

export const playIcon = css`
  display: block;
  height: 94px;
  width: auto;
`
