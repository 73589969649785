export function HandRightIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80.49 38.52" {...props}>
      <g
        data-name="Gruppe 207"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Pfad 68"
          d="M11.948 9.805h8.191a4.207 4.207 0 003.179-1.381c2.928-2.412 9.158-7.155 13.081-7.4a9.358 9.358 0 018.85 4.323h0l30.818.873a3.152 3.152 0 013.424 3.489c0 3.294-2.7 3.455-3.4 3.669-.105.032-21 .919-21 .919s2.112.873 2.112 4.079-1.529 4.556-4.733 4.556h.774s3.069-.043 3.069 3.863a3.9 3.9 0 01-3.065 3.865 3.471 3.471 0 011.261 3.195c-.245 2.142-2.716 3.293-4.279 3.416s-9.218 0-9.218 0-13.194.721-18.939-5.35c-1.257-1.331-9.991-.658-9.991-.658"
        />
        <path
          data-name="Pfad 69"
          d="M53.247 30.667l-12.417-.07a3.6 3.6 0 01-2.66-3.795c0-4.221 3.74-3.863 3.74-3.863h10.27"
        />
        <path data-name="Pfad 70" d="M45.091 22.938h-4.247s-2.686-.5-2.686-3.024c0-2.559.591-2.706 1.3-3.344" />
        <path
          data-name="Pfad 71"
          d="M20.139 9.805a4.207 4.207 0 003.179-1.381c2.928-2.412 9.158-7.155 13.081-7.4 5.267-.327 9.552 3.073 9.77 7.465.14 2.81.111 5.631.081 8.444-.018 1.71 1.327 5.182-1.459 5.039a5.609 5.609 0 01-2.414-.788c-2.581-1.541-3.186-4.883-3.234-7.647a1.158 1.158 0 00-2.3-.219c-.2 1.912-.451 7.462-6.1 7.462l-3.457-.3"
        />
        <path data-name="Pfad 72" d="M41.011 37.277s-13.194.721-18.939-5.35" />
        <path data-name="Linie 10" d="M55.091 14.304h-8.826" />
        <path data-name="Pfad 73" d="M41.011 37.278s-2.431-.718-2.431-3.023c0-3.588 3.044-3.654 3.044-3.654" />
        <path
          data-name="Pfad 74"
          d="M1 9.044a1.437 1.437 0 011.435-1.436h7.881a1.433 1.433 0 011.436 1.435v23.294a1.434 1.434 0 01-1.435 1.435H2.433a1.433 1.433 0 01-1.434-1.435z"
        />
      </g>
    </svg>
  )
}
