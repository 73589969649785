export function PlayIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.937 94.937" {...props}>
      <path
        d="M47.469 0a46.014 46.014 0 0 1 23.734 6.412 47.966 47.966 0 0 1 17.322 17.323 46.014 46.014 0 0 1 6.413 23.734 46.014 46.014 0 0 1-6.412 23.734 47.966 47.966 0 0 1-17.323 17.323 46.014 46.014 0 0 1-23.734 6.412 46.014 46.014 0 0 1-23.734-6.412A47.966 47.966 0 0 1 6.412 71.203 46.014 46.014 0 0 1 0 47.469a46.014 46.014 0 0 1 6.412-23.734A47.966 47.966 0 0 1 23.735 6.413 46.014 46.014 0 0 1 47.469 0Zm22.2 52.063a4.362 4.362 0 0 0 2.3-4.02 4.362 4.362 0 0 0-2.3-4.02l-33.684-20.48a4.272 4.272 0 0 0-4.594 0 4.362 4.362 0 0 0-2.3 4.02v39.812a4.362 4.362 0 0 0 2.3 4.02 4.272 4.272 0 0 0 4.594 0Z"
        fill="#fff"
      />
    </svg>
  )
}
