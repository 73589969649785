import React from 'react'
import { Spacing } from '~/base'

import * as styles from './Stack.css'

type Props = {
  spacing?: Spacing
  includeLastSpace?: boolean
  children: React.ReactNode
}

// TODO: Return type.
export function Stack({ spacing, includeLastSpace = false, children }: Props): any {
  return React.Children.map(children, (child: React.ReactChild) => {
    return <div css={styles.item}>{child}</div>
  })
}
