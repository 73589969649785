import { css } from '@emotion/react'
import { breakpoints } from '~/base'

export const wrapper = css`
  margin-bottom: 100px;
`

export const heading = css`
  text-align: center;
  margin-bottom: 3rem;
`

export const main = css``

/** Timeline */

export const timeline = css`
  @media ${breakpoints.small} {
    text-align: center;
  }
`

export const timelineStart = css`
  color: var(--colors-primary);
  padding-bottom: 1.5rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: var(--colors-primary);
    position: absolute;
    bottom: 0;

    @media ${breakpoints.small} {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const timelineEnd = css`
  color: var(--colors-primary);
  padding-top: 1.5rem;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: var(--colors-primary);
    position: absolute;
    top: 0;

    @media ${breakpoints.small} {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const timelineIcon = css`
  color: var(--colors-primary);
  display: block;
  margin: 6px 0;
  width: 48px;

  @media ${breakpoints.small} {
    margin: 6px auto;
  }
`

/** Timeline: Items */

export const timelineItems = css``
