import { Container } from '~/components'

import * as styles from './PageMatomoBlock.css'

export function PageMatomoBlock() {
  return (
    <div css={styles.wrapper}>
      <Container width="small">
        <strong>Opt-out</strong>
        <br />
        <iframe
          style={{ border: 0, height: '200px', width: '600px', maxWidth: '100%', margin: '-2px 0 0 -7px' }}
          className="matomo"
          src="https://analytics.sitewalk.com/index.php?module=CoreAdminHome&amp;action=optOut&amp;language=de"
        />
      </Container>
    </div>
  )
}
