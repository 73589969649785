import type { ExhibitorStory } from '~/features/exhibitors'

type ExhibitorLocations = {
  [booth: string]: ExhibitorStory
}

export function useExhibitorLocations(exhibitors: ExhibitorStory[]): ExhibitorLocations {
  const locations = exhibitors.reduce<ExhibitorLocations>((accumulator, exhibitor) => {
    if (exhibitor.content.location_booth) {
      const booth: string = exhibitor.content.location_booth
      accumulator[booth] = exhibitor
    }

    return accumulator
  }, {})

  return locations
}
