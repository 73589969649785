import { render } from 'storyblok-rich-text-react-renderer'

// TODO: Typings. See https://www.npmjs.com/package/storyblok-rich-text-react-renderer
type Props = {
  document: any
  resolvers?: any
}

export function RichText({ document, resolvers }: Props) {
  return render(document)
}
