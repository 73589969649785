import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { fadeVariants } from '~/base'
import { Container, Title } from '~/components'
import { ExhibitorTimelineBlockStoryblok } from '~/storyblok'
import { ExhibitorTimelineBlockItem } from './ExhibitorTimelineBlockItem'

import * as styles from './ExhibitorTimelineBlock.css'

type Props = {
  blok: ExhibitorTimelineBlockStoryblok
}

export function ExhibitorTimelineBlock({ blok }: Props) {
  const { ref: startRef, inView: inViewStart } = useInView({
    rootMargin: '100px 0px -100px 0px',
    delay: 200,
  })

  const { ref: endRef, inView: inViewEnd } = useInView({
    rootMargin: '100px 0px -100px 0px',
    delay: 200,
  })

  return (
    <div css={styles.wrapper}>
      <Container>
        <div css={styles.heading}>
          <Title label={blok.label} size="xlarge">
            <div dangerouslySetInnerHTML={{ __html: blok.title || '' }} />
          </Title>
        </div>
        <div css={styles.main}>
          <div css={styles.timeline}>
            <div css={styles.timelineStart}>
              <motion.div
                variants={fadeVariants}
                ref={startRef}
                initial="hidden"
                animate={inViewStart ? 'visible' : 'hidden'}
              >
                <svg viewBox="0 0 549 517" xmlns="http://www.w3.org/2000/svg" css={styles.timelineIcon}>
                  <path
                    d="M432 26c44.1 0 80 35.89 80 80 0 8.84-7.2 16-16 16s-16-7.16-16-16c0-26.47-21.5-48-48-48-8.8 0-16-7.156-16-16 0-8.844 7.2-16 16-16Zm-315 0c8.84 0 16 7.2 16 16s-7.16 16-16 16c-26.47 0-48 21.5-48 48 0 8.8-7.156 16-16 16-8.844 0-16-7.2-16-16 0-44.1 35.89-80 80-80ZM487.333333 0C521.327083 0 549 27.6652083 549 61.6666667 549 68.4808333 543.45 74 536.666667 74c-6.783334 0-12.333334-5.5191667-12.333334-12.3333333 0-20.4039584-16.572916-37-37-37C480.55 24.6666667 475 19.1505833 475 12.3333333 475 5.51608333 480.55 0 487.333333 0ZM61.6666667 0C68.4808333 0 74 5.55 74 12.3333333c0 6.7833334-5.5191667 12.3333334-12.3333333 12.3333334-20.4039584 0-37 16.5729166-37 37C24.6666667 68.45 19.1505833 74 12.3333333 74 5.51608333 74 0 68.45 0 61.6666667 0 27.6729167 27.6652083 0 61.6666667 0ZM290 300l57.6 43.2c7.1 5.3 8.5 15.3 3.2 22.4-5.3 7.1-15.3 8.5-22.4 3.2l-64-48c-4-3-7.3-7.8-7.3-12.8V180c0-8.8 8.1-16 16-16 9.7 0 16.9 7.2 16.9 16v120ZM54.69 511.3c-6.25-6.2-6.25-16.4 0-22.6l50.01-50C70.62 399.4 50 348.1 50 291.1c0-122.8 100.3-224 224-224s224 101.2 224 224c0 57-20.6 108.3-54.7 147.6l50 50c6.3 6.2 6.3 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0l-50-50C381.4 495.4 330.1 516 274 516c-56.1 0-107.4-20.6-146.7-54.7l-49.99 50c-6.24 6.3-16.38 6.3-22.62 0ZM273.1 484C380 484 466 398 466 292c0-106.9-86-192-192.9-192-106 0-192 85.1-192 192 0 106 86 192 192 192Z"
                    fill="currentColor"
                    fillRule="nonzero"
                  />
                </svg>
                <strong>Dein Tag startet</strong>
              </motion.div>
            </div>
            <div css={styles.timelineItems}>
              {blok.items?.map((blok, index) => (
                <ExhibitorTimelineBlockItem blok={blok} index={index} key={blok._uid} />
              ))}
            </div>
            <div css={styles.timelineEnd}>
              <motion.div
                variants={fadeVariants}
                ref={endRef}
                initial="hidden"
                animate={inViewEnd ? 'visible' : 'hidden'}
              >
                <strong>Dein Tag endet</strong>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" css={styles.timelineIcon}>
                  {/*! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}
                  <path
                    fill="currentColor"
                    d="M304 32C330.5 32 352 53.53 352 80C352 88.84 359.2 96 368 96S384 88.84 384 80C384 35.89 348.1 0 304 0C295.2 0 288 7.156 288 16S295.2 32 304 32zM417.9 106.9c-42.22 0-56.93 36.81-59.45 45.39l-112.2-111.9C235.7 29.81 221.6 23.1 206.7 23.1c-5.969 0-39.95 2.27-52.29 36.15c-5.043-1.463-10.32-2.219-15.72-2.219c-28.2 0-55.64 22.11-55.64 55.29c0 5.376 .755 10.77 2.255 16.01c-17.53 6.444-36.26 24.1-36.26 51.85c0 12.67 4.195 25.46 12.45 36.01C57.27 219.3 31.1 233.3 31.1 266c0 14.93 5.824 29.91 17.25 41.3l125 124.7C205.3 462.1 246.5 480 290.4 480s85.17-17.03 116.1-47.94l20.04-19.1c34.43-34.35 53.39-80.03 53.39-128.6V168.8C480 134.7 452.2 106.9 417.9 106.9zM447.1 283.4c0 39.77-15.83 77.92-44.02 106l-20.04 19.1c-25.78 25.72-59.65 38.59-93.52 38.59s-67.73-12.86-93.52-38.59L71.88 284.7C66.77 279.6 63.95 272.7 63.95 265.8c0-15.94 13.91-23.3 23.69-23.3c6.152 0 12.31 2.342 17 7.025l75.26 75.09c2.348 2.34 5.426 3.512 8.502 3.512c6.464 0 12.02-5.195 12.02-11.99c0-3.07-1.173-6.141-3.52-8.482l-108-107.8C83.77 194.8 80.96 187.9 80.96 181c0-15.94 13.9-23.3 23.69-23.3c6.154 0 12.31 2.344 17 7.027L230.9 273.8C233.3 276.1 236.3 277.3 239.4 277.3c6.506 0 12.02-5.241 12.02-11.1c0-3.07-1.174-6.14-3.521-8.482L122.9 132.1C117.8 126.1 114.1 120 114.1 113.2c0-15.94 13.91-23.3 23.68-23.3c6.154 0 12.31 2.342 17 7.025l126.3 125.1c2.348 2.342 5.426 3.514 8.502 3.514c6.523 0 12.02-5.248 12.02-11.1c0-3.07-1.173-6.141-3.519-8.485l-108-107.8C185.8 93.03 182.1 86.07 182.1 79.23c0-15.83 13.71-23.3 23.68-23.3c6.154 0 12.31 2.344 17 7.027L375.3 214.3c1.5 1.5 3.348 2.17 5.158 2.17c3.791 0 7.428-2.938 7.428-7.371V170.4c0-5.65 4.392-31.57 30.05-31.57c16.61 0 30.05 13.42 30.05 29.99V283.4zM80 416C53.53 416 32 394.5 32 368C32 359.2 24.84 352 16 352S0 359.2 0 368C0 412.1 35.88 448 80 448C88.84 448 96 440.8 96 432S88.84 416 80 416z"
                  />
                </svg>
              </motion.div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
