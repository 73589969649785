import { LocationIcon, Text } from '~/components'
import { ExhibitorStory } from '../types'
import { IconLink } from './IconLink'
import { formatURL } from './formatURL'

import * as styles from './Contact.css'

type Props = {
  exhibitor: ExhibitorStory
}

export function Contact({ exhibitor }: Props) {
  return (
    <div css={styles.wrapper}>
      <div css={styles.box}>
        <div css={styles.columns}>
          <div css={styles.column}>
            <div css={styles.brand}>
              <div css={styles.logo}>
                <a href={exhibitor.content.website} target="_blank" rel="noopener noreferrer">
                  <img src={exhibitor.content.logo.filename} alt="" css={styles.logoImage} />
                </a>
              </div>
            </div>
          </div>
          <div css={styles.column}>
            <Text>{exhibitor.content.address}</Text>
          </div>
          <div css={styles.column}>
            {exhibitor.content.phone && (
              <>
                {exhibitor.content.phone}
                <br />
              </>
            )}
            {exhibitor.content.email && (
              <>
                <a href={`mailto:${exhibitor.content.email}`}>{exhibitor.content.email}</a> <br />
              </>
            )}
            {exhibitor.content.website && (
              <a href={exhibitor.content.website} target="_blank" rel="noreferrer">
                {formatURL(exhibitor.content.website)}
              </a>
            )}
          </div>
          <div css={styles.column}>
            <IconLink href="">
              <LocationIcon css={styles.locationIcon} />
              <Text>
                {exhibitor.content.location_booth || exhibitor.content.location_site ? (
                  <div>
                    {exhibitor.content.location_site && (
                      <>
                        {exhibitor.content.location_site}
                        <br />
                      </>
                    )}
                    {exhibitor.content.location_booth && <>Stand {exhibitor.content.location_booth}</>}
                  </div>
                ) : (
                  <em>Standort folgt</em>
                )}
              </Text>
            </IconLink>
          </div>
        </div>
      </div>
    </div>
  )
}
