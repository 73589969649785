import { FontSize, FontWeight } from 'base/font'

import * as styles from './Text.css'

type Props = {
  size?: FontSize
  weight?: FontWeight
  children: React.ReactNode
  css?: any
}

export function Text({ size = 'medium', weight = 'regular', children, css, ...props }: Props) {
  return (
    <div css={[styles.text({ weight, size }), css]} {...props}>
      {children}
    </div>
  )
}
