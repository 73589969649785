import { Container, RichText } from '~/components'
import { PageTextBlockStoryblok } from '~/storyblok'

type Props = {
  blok: PageTextBlockStoryblok
}

export function PageTextBlock({ blok }: Props) {
  return <Container width="small">{blok.text && <RichText document={blok.text} />}</Container>
}
