import { css } from '@emotion/react'

import { Width, widths } from './width'

type Options = {
  width?: Width
  padding?: boolean
}

const widthVariants = {
  small: css``,
  medium: css`
    width: ${widths['medium']};
    max-width: 100%;
  `,
  large: css``,
  xlarge: css``,
}

export function container({ width = 'medium', padding = false }: Options = {}) {
  return css`
    width: ${widths[width]}px;
    max-width: 100%;

    ${padding &&
    css`
      width: calc(${widths[width]}px + 2 * var(--gap));
      padding-left: var(--gap);
      padding-right: var(--gap);
    `}
  `
}
