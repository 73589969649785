import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { Container, HandDownIcon, RichText, Stack, Title } from '~/components'
import { Exhibitors } from '~/features/exhibitors'
import { resizeImage } from '~/storyblok/resizeImage'
import { ExhibitorStory } from '../types'
import { Contact } from './Contact'

import * as styles from './Exhibitor.css'

type Props = {
  exhibitor: ExhibitorStory
}

export function Exhibitor({ exhibitor }: Props) {
  return (
    <div css={styles.wrapper}>
      <Stack>
        <>
          <div css={styles.intro}>
            <div css={styles.introContent}>
              <div css={styles.introMedia}>
                <div css={styles.media}>
                  <img
                    src={resizeImage(exhibitor.content.image, { width: 795, height: 795 }).url}
                    alt=""
                    css={styles.image}
                  />
                </div>
              </div>
              <div css={styles.introMain}>
                <div css={styles.introMainInner}>
                  <Title size="xlarge" label={exhibitor.content.categories.join(', ')}>
                    {exhibitor.content.title}
                  </Title>
                  {exhibitor.content.text && (
                    <div css={styles.text}>
                      <RichText document={exhibitor.content.text} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Contact exhibitor={exhibitor} />
        </>
      </Stack>

      {/* <LotteryCTA exhibitor />*/}

      <Stack>
        {exhibitor.content.blocks?.map((blok) => (
          <div key={blok._uid} {...storyblokEditable(blok)}>
            <StoryblokComponent blok={blok} />
          </div>
        ))}
      </Stack>

      <div css={styles.more}>
        <Container>
          <HandDownIcon css={styles.moreIcon} />
          <Title size="xlarge" label="next-step">
            Entdecke weitere Aussteller:innen
          </Title>
        </Container>
      </div>

      <Stack>
        <Container>
          <Exhibitors />
        </Container>
      </Stack>

      {/*
      <div css={styles.plan}>
        <Container>
          <Plan />
        </Container>
      </div>
      */}
    </div>
  )
}
