import Fuse from 'fuse.js'
import { useContext } from 'react'
import { ExhibitorSearch } from './'
import { ExhibitorsContext } from './ExhibitorsProvider'

type Props = {
  search?: ExhibitorSearch
}

// TODO: use memo

/**
 * useExhibitors returns all exhibitors or allows the exhibitors to be filtered by keyword or categories.
 * When filters/search are used, this means we are looking for results. In that case, we extract all Exhibitor -> ExhibitorMemberBlock to be shown as stand-alone as well. But they link to their parent Exhibitor.
 */
export function useExhibitors({ search }: Props = {}) {
  const allExhibitors = useContext(ExhibitorsContext) || []
  let exhibitors = [...allExhibitors]

  // Map over exhibitor.blocks and create a dynamic content string for each block.
  exhibitors = exhibitors.map((exhibitor) => {
    const blocks: any = []

    if (exhibitor.content.blocks) {
      for (const block of exhibitor.content.blocks) {
        let content = ''
        if (block.component === 'ExhibitorsBlock') {
          for (const nestedExhibitor of block.exhibitors) {
            content += nestedExhibitor.name + ' '
          }
        }
        blocks.push({ content })
      }
    }

    return {
      ...exhibitor,
      blocks,
    }
  })

  // Filter: Keywords
  if (search?.keywords) {
    const fuse = new Fuse(exhibitors, {
      includeScore: true,
      minMatchCharLength: 3,
      keys: ['name', 'title', 'address', 'email', 'category', 'phone', 'website', 'textRaw', 'blocks.content'],
    })

    const result = fuse.search(search.keywords)

    exhibitors = result.map((resultItem) => resultItem.item)
  }

  // Filter: Categories
  if (search?.categories && search.categories.length > 0) {
    exhibitors = exhibitors.filter((exhibitor) => {
      for (const category of exhibitor.content.categories) {
        if (search!.categories!.includes(category.toString())) {
          return true
        }
      }
      return false
    })
  }

  return exhibitors
}
