import { FontSize, FontWeight } from 'base/font'

import * as styles from './Heading.css'

type Props = {
  level?: Level
  size?: FontSize
  weight?: FontWeight
  children: React.ReactNode
  css?: any
}

type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export function Heading({ size = 'xxlarge', level = 'h1', weight = 'bold', children, css, ...props }: Props) {
  const Element = level
  return (
    <Element css={[styles.heading({ weight, size }), css]} {...props}>
      {children}
    </Element>
  )
}
