import { css } from '@emotion/react'
import { breakpoints, container, radius } from '~/base'

export const wrapper = css`
  ${container({ padding: true })}
  margin-left: auto;
  margin-right: auto;

  a {
    text-decoration: none;
  }

  @media ${breakpoints.xlarge} {
  }
`

export const box = css`
  ${radius('medium')}
  background: var(--colors-primary);
  color: #fff;
  padding: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
`

export const content = css``

export const columns = css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media ${breakpoints.large} {
    flex-direction: row;
  }
`

export const column = css`
  flex: auto;

  // Brand
  &:nth-of-type(1) {
  }

  &:nth-of-type(2),
  &:nth-of-type(3),
  &:nth-of-type(4) {
    padding: 24px;
  }

  &:nth-of-type(2),
  &:nth-of-type(3) {
    position: relative;

    &::after {
      content: '';
      height: 2px;
      display: block;
      position: absolute;
      left: 0;
      right: 48px;
      bottom: 0;
      background: #fff;

      @media ${breakpoints.large} {
        bottom: 20px;
        width: 1px;
        height: 100%;
        right: 0;
        left: auto;
      }
    }
  }

  @media ${breakpoints.large} {
    flex: 1;

    &:nth-of-type(1) {
      flex-basis: 100%;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      flex: auto;
    }

    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      padding: 24px 48px;
    }

    &:nth-of-type(4) {
      flex: 0 0 auto !important;
      width: 240px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media ${breakpoints.xlarge} {
    &:nth-of-type(1),
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      flex: 1;
      flex-basis: 0;
    }
  }
`

export const brand = css`
  ${radius('medium')}
  background: #fff;
  padding: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${breakpoints.large} {
    border-bottom-left-radius: 36px;
  }
`

export const logo = css`
  width: 220px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  > a {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const logoImage = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const locationIcon = css`
  height: 30px;
`
