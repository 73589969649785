import * as styles from './PlanMobile.css'

export function PlanMobile() {
  return (
    <a href={`/LIAN_Ne24_Webseite_Plan_A3_Web.pdf`} target="_blank" rel="noreferrer">
      <img
        src="/LIAN_next-step_Magazin_Hallenplan_2024.svg"
        alt="Hallenplan 2024"
        width="800"
        height="auto"
        css={styles.image}
      />
    </a>
  )
}
