export function HandWaveIcon({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.858 62.891" {...props}>
      <g data-name="Gruppe 180" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth={2}>
        <g data-name="Gruppe 179" strokeLinecap="round">
          <path
            data-name="Pfad 75"
            d="M38.264 53.024a29.841 29.841 0 00-4.708-5.383c-2.3-1.636-7.769-7.814-9.579-10.129s-14.242-7.643-14.248-7.64a2.121 2.121 0 011.3-4.013l.545.1 9.627 4.187a1.002 1.002 0 10.955-1.762c-.7-.507-11.6-8.309-11.6-8.307a2.2 2.2 0 01-.725-.694 2.226 2.226 0 013.02-3.12l.349.265 5.692 4.42a45.53 45.53 0 005.49 3.933 1.002 1.002 0 001.395-1.44L15.023 9.756a2.225 2.225 0 013.724-2.437l10.456 12.883a1 1 0 001.389.29c.782-.511.281-1.509-.015-2.176a26.93 26.93 0 00-.951-1.9c-.885-1.655-1.836-3.277-2.8-4.891q-1.265-2.122-2.573-4.227c-.251-.4-.5-.812-.76-1.213l.161.3a2.227 2.227 0 013.726-2.438l.171.261 7.234 11.057c3.03 4.63 5.676 10.092 8.618 9.158s-.044-3.607 2.5-9.321 5.515-2.947 5.515-2.947a48.408 48.408 0 00-1 7.187c.039 2.206.871 7.778.375 9.922s-.884 11.16-.26 12.239c.221.387.856 1.366 1.58 2.472"
          />
          <path
            data-name="Rechteck 173"
            d="M36.829 53.965L54.126 42.64a.874.874 0 011.21.253l3.667 5.6a.875.875 0 01-.253 1.212L41.454 61.029a.875.875 0 01-1.211-.253l-3.667-5.6a.874.874 0 01.253-1.211z"
            strokeWidth="2.00088"
          />
        </g>
        <path data-name="Pfad 76" d="M35.631.996a5.735 5.735 0 015.194 6.23" />
        <path data-name="Pfad 77" d="M32.052 4.112a5.736 5.736 0 015.194 6.23" />
        <path data-name="Pfad 78" d="M7.203 38.347a5.735 5.735 0 01-6.207-5.221" />
        <path data-name="Pfad 79" d="M10.307 34.832a5.736 5.736 0 01-6.208-5.221" />
      </g>
    </svg>
  )
}
