import * as styles from './Container.css'

type Props = {
  width?: Width
  children: React.ReactNode
}

type Width = 'small' | 'medium' | 'large' | 'xlarge'

export function Container({ width = 'medium', children }: Props) {
  return <div css={styles.containerStyle({ width })}>{children}</div>
}
