import { css } from '@emotion/react'
import { breakpoints, font, rack, rackContent, radius } from '~/base'

export const wrapper = css`
  ${rack({ color: 'primary', content: true })}
  ${radius('xxlarge', true)}
`

export const container = css`
  ${rackContent()}
  padding-top: 48px;
  padding-bottom: 48px;

  @media ${breakpoints.large} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const content = css`
  display: flex;
  flex-wrap: wrap;
  margin: -24px;
`

export const main = css`
  padding: 24px;
  flex-basis: 66.666666%;
  flex: auto;
`

export const meta = css`
  padding: 24px;
  flex-basis: 33.333333%;
  flex: auto;
`

/** Form */

export const form = css``

export const formHidden = css`
  display: none;
`

export const formItems = css`
  margin: 0 -12px;
  display: flex;
  flex-wrap: wrap;
`

export const formItem = css`
  padding: 12px;
  flex: 1;
  min-width: 280px;
`

export const formItemEnd = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const sharedInputStyles = css`
  display: block;
  font-family: inherit;
  font-size: inherit;
  padding: 15px;
  background: #fff;
  border-top-right-radius: 20px;
  border: none;
  outline: none;
  width: 100%;
  color: var(--colors-base);
  ${font({ size: 'small' })}

  &::placeholder {
    color: #505050;
  }
`

export const formInput = css`
  ${sharedInputStyles}
`

export const formTextarea = css`
  ${sharedInputStyles}
  height: 200px;
`

export const formMeta = css`
  ${font({ size: 'small' })}
  margin-bottom: 36px;
`

export const formAction = css``

export const formButton = css`
  font-size: inherit;
`

export const formButtonIcon = css`
  height: 38px;
  position: relative;
  top: 6px;
`

/** Person */

export const person = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const personPhoto = css`
  width: 244px;
  height: 244px;
  border-radius: 244px;
  border: 3px solid #fff;
  position: relative;
  margin-bottom: 1rem;
`

export const personPhotoImage = css`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 244px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`

export const personMain = css`
  a {
    text-decoration: none;
  }
`

export const personTitle = css`
  margin: 0;
  padding: 0;
  font-size: 1rem;
`

/** Status */

export const status = css``

export const statusRetry = css`
  border: 0;
  outline: none;
  text-decoration: underline;
  padding: 0;
  font: inherit;
  color: inherit;
  margin: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: none;
  margin-top: 0.5em;
`
