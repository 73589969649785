import NextLink from 'next/link'
import { motion } from 'framer-motion'

import * as styles from './CTA.css'

// TODO: Types. React.HTMLAttributes<HTMLButtonElement> & {
type Props = {
  css?: any
  href?: string
  target?: any
  onClick?: any
  icon?: React.ReactNode
  iconPosition?: 'left' | 'top'
  iconAnimation?: boolean
  children: React.ReactNode
  type?: any
  rel?: string
}

const iconLeftMotion = {
  rest: { x: 0 },
  hover: {
    x: [0, -15, 0],
  },
}

const iconTopMotion = {
  rest: { y: 0 },
  hover: {
    y: [0, -15, 0],
  },
}

export function CTA({
  icon,
  iconPosition = 'left',
  css,
  href,
  target,
  onClick,
  children,
  type = 'button',
  ...props
}: Props) {
  const ctaType = href ? 'link' : 'button'

  function Content() {
    return (
      <>
        {icon && iconPosition === 'top' && (
          <motion.span variants={iconTopMotion} css={styles.iconTop}>
            {icon}
          </motion.span>
        )}
        {icon && iconPosition === 'left' && (
          <motion.span variants={iconLeftMotion} css={styles.iconLeft}>
            {icon}
          </motion.span>
        )}
        <span css={styles.text}>{children}</span>
      </>
    )
  }

  // TODO: Handle externalLink and internalLink
  if (ctaType === 'link') {
    return (
      <NextLink href={href!} passHref={true} target={target}>
        <motion.div css={[styles.cta, css]} initial="rest" whileHover="hover" animate="rest" {...props}>
          <Content />
        </motion.div>
      </NextLink>
    )
  }

  if (ctaType === 'button') {
    return (
      <motion.button
        type={type}
        css={[styles.cta, icon && iconPosition === 'top' && styles.ctaVertical, css]}
        onClick={onClick}
        initial="rest"
        whileHover="hover"
        animate="rest"
        {...props}
      >
        <Content />
      </motion.button>
    )
  }

  return null
}
