import { css } from '@emotion/react'
import { font } from '~/base'

export function label({ color }) {
  return css`
    ${font({ weight: 'bold' })}

    ${color &&
    css`
      color: var(--colors-primary);
    `}
  `
}
