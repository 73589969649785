import * as styles from './Label.css'

type Props = {
  color?: boolean
  css?: any // TODO: typings
  children: React.ReactNode
}

export function Label({ color, css, children, ...props }: Props) {
  return (
    <div css={[styles.label({ color }), css]} {...props}>
      {children}
    </div>
  )
}
