import { css } from '@emotion/react'
import { font } from '~/base'

export const cta = css`
  ${font({ weight: 'bold' })}
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  color: inherit;
  border: 0;
  font-size: inherit;
  position: relative;
  transition: color 0.35s ease;
  // color: var(--colors-primary);
  font-weight: 700;
  display: inline-block;
  padding-bottom: 0px;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;

  &:hover {
    // color: var(--colors-primary);
  }
`

export const ctaVertical = css`
  flex-direction: column;
`

const icon = css`
  display: block;
  margin-right: 12px;
  margin-bottom: -4px;

  > svg {
    display: block;
  }
`

export const iconTop = css`
  ${icon}
  margin-bottom: 12px;
`

export const iconLeft = css`
  ${icon}
`

export const text = css`
  display: block;
  position: relative;
  padding-bottom: 1px;

  &::after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: currentColor;
  }
`
