import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FontSize, fadeVariants } from '~/base'
import { Heading } from '../Heading'
import { Label } from '../Label'

import * as styles from './Title.css'

type Props = {
  color?: boolean
  label?: string
  size?: FontSize
  spacing?: 'medium' | 'large' | 'xlarge'
  children: React.ReactNode
}

export function Title({ label, color = true, size, spacing = 'medium', children }: Props) {
  const [ref, inView] = useInView({
    rootMargin: '-60px 0px -60px 0px',
  })

  return (
    <motion.div
      css={styles.title({ spacing })}
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={fadeVariants}
    >
      {label && (
        <Label color={color} css={styles.label}>
          {label}
        </Label>
      )}
      <Heading size={size}>{children}</Heading>
    </motion.div>
  )
}
