import { CTA, Container, HandRightIcon, RichText, Title } from '~/components'
import { ExhibitorMemberBlockStoryblok } from '~/storyblok'
import { resizeImage } from '~/storyblok/resizeImage'
import { formatURL } from '../Exhibitor/formatURL'

import * as styles from './MemberBlock.css'

type Props = {
  blok: ExhibitorMemberBlockStoryblok
}

export function MemberBlock({ blok }: Props) {
  const reverse = blok.visual_order === 'main_media'
  const hasCTA = blok.cta_text && blok.cta_url

  const hasContactMain = blok.phone || blok.email || blok.website

  return (
    <div css={styles.wrapper}>
      <Container>
        <div css={[styles.content, reverse && styles.contentReverse]}>
          <div css={styles.media}>
            <img src={resizeImage(blok.image, { width: 798, height: 532 }).url} alt="" css={styles.image} />
          </div>
          <div css={[styles.main, reverse ? styles.mainLeft : styles.mainRight]}>
            <Title label={blok.label} size="xlarge">
              {blok.title}
            </Title>
            <RichText document={blok.text} />
            {hasCTA && (
              <div css={styles.cta}>
                <CTA
                  href={blok.cta_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<HandRightIcon css={styles.ctaIcon} />}
                >
                  {blok.cta_text}
                </CTA>
              </div>
            )}
          </div>
        </div>
        <div css={[styles.contact, reverse ? styles.contactLeft : styles.contactRight]}>
          <div css={[styles.contactBox, reverse ? styles.contactBoxLeft : styles.contactBoxRight]}>
            {blok.logo && (
              <div css={styles.contactBrand}>
                <div css={styles.contactLogo}>
                  <a href={blok.website} target="_blank" rel="noopener noreferrer">
                    <img src={blok.logo.filename} alt="" css={styles.contactLogoImage} />
                  </a>
                </div>
              </div>
            )}
            {hasContactMain && (
              <ul css={styles.contactMain}>
                {blok.phone && <li>{blok.phone}</li>}
                {blok.email && (
                  <li>
                    <a href={`mailto:${blok.email}`}>{blok.email}</a>
                  </li>
                )}
                {blok.website && (
                  <li>
                    <a href={blok.website} target="_blank" rel="noreferrer">
                      {formatURL(blok.website)}
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}
